import { purple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import TextFieldComponent from "../../../.reUseableComponents/TextField/TextFieldComponent";
import TextFieldLableComponent from "../../../.reUseableComponents/TextField/TextFieldLableComponent";
import { CreateClientOrderBox } from "../../../api/AxiosInterceptors";
import {
  GridContainer,
  GridItem,
  handleCalculateVolume,
} from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";

const CreateBankModal = (props) => {
  const { open, onClose } = props;
  const [stringFields, setStringFields] = useState({
    bankName: "",
    accounttitle: "",
    iban: "",
    swiftcode: "",
    branchname: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStringFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateBank = async () => {
    console.log(stringFields);
    // setLoading(true);
    // const body = {};
    // await CreateClientOrderBox(body)
    //   .then((res) => {
    //     if (!res?.data?.isSuccess) {
    //       errorNotification("Failed to create order box.");
    //     } else {
    //       successNotification("Created Successfully.");
    //     }
    //   })
    //   .catch((e) => {
    //     errorNotification("An error occurred while creating the order box.");
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //     onClose();
    //   });
  };

  return (
    <>
      <ModalComponent
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={"Create Bank"}
        actionBtn={
          <ModalButtonComponent
            title={"Create Bank"}
            bg={purple}
            loading={loading}
            onClick={(e) => handleCreateBank()}
          />
        }
      >
        <GridContainer spacing={1}>
          <GridItem sm={12} md={12} lg={12}>
            <TextFieldLableComponent title={"Bank Name"} />
            <TextFieldComponent
              sx={{
                fontsize: "14px",
                marginTop: "4px",
                "& .css-setb27-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "8px!important",
                },
              }}
              type="text"
              placeholder={"Enter Bank Name"}
              value={stringFields.bankName}
              name="bankName"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem sm={12} md={12} lg={12}>
            <TextFieldLableComponent title={"Account Title"} />
            <TextFieldComponent
              sx={{
                fontsize: "14px",
                marginTop: "4px",
                "& .css-setb27-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "8px!important",
                },
              }}
              type="text"
              placeholder={"Enter Title"}
              value={stringFields.accounttitle}
              name="accounttitle"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem sm={12} md={12} lg={12}>
            <TextFieldLableComponent title={"IBAN"} />
            <TextFieldComponent
              sx={{
                fontsize: "14px",
                marginTop: "4px",
                "& .css-setb27-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "8px!important",
                },
              }}
              type="text"
              placeholder={"Enter IBAN"}
              value={stringFields.iban}
              name="iban"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem sm={12} md={12} lg={12}>
            <TextFieldLableComponent title={"SwiftCode"} />
            <TextFieldComponent
              sx={{
                fontsize: "14px",
                marginTop: "4px",
                "& .css-setb27-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "8px!important",
                },
              }}
              type="text"
              placeholder={"Enter SwiftCode"}
              value={stringFields.swiftcode}
              name="swiftcode"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem sm={12} md={12} lg={12}>
            <TextFieldLableComponent title={"Branch Name"} />
            <TextFieldComponent
              sx={{
                fontsize: "14px",
                marginTop: "4px",
                "& .css-setb27-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "8px!important",
                },
              }}
              type="text"
              placeholder={"Enter Branch Name"}
              value={stringFields.branchname}
              name="branchname"
              onChange={handleChange}
            />
          </GridItem>
        </GridContainer>
      </ModalComponent>
    </>
  );
};

export default CreateBankModal;
