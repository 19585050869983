export const inventorySaleEnglish = {
  PRODUCT_INVENTORY_SALES_TEXT: "Inventory Sales",
  INVENTORY_SALE_S_TEXT: "Inventory Sale(s)",
  PRODUCT_INVENTORY_SALES_ORDER_NO: "Order No.",
  PRODUCT_INVENTORY_SALES_STORE_NAME: "Store Name",
  PRODUCT_INVENTORY_SALES_FULFILLMENT_STATUS: "Fulfillment Status",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_QTY: "Total Item(s) Qty",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_DISCOUNT: "Total Item(s) Discount",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_PRICE: "Total Item(s) Price",
  PRODUCT_INVENTORY_SALES_FULFILLED_ONLY: "Fulfilled Only",
  PRODUCT_INVENTORY_SALES_IN_TRANSIT_ONLY: "InTransit Only",
  PRODUCT_INVENTORY_SALES_START_DATE: "Start Date",
  PRODUCT_INVENTORY_SALES_END_DATE: "End Date",
  PRODUCT_INVENTORY_SALES_STATION: "Station",
  PRODUCT_INVENTORY_SALES_CARRIER_STATUS: "Carrier Status",
  PRODUCT_INVENTORY_SALES_SALES_PERSON: "Sales Person",
  PRODUCT_INVENTORY_SALES_REGION_CITY: "Region/City",
  PRODUCT_INVENTORY_SALES_FULFILLED_ONLY: "Fulfilled Only",
  PRODUCT_INVENTORY_SALES_IN_TRANSIT_ONLY: "InTransit Only",
  PRODUCT_INVENTORY_SALES_FULFILLMENT_STATUS: "Fulfillment Status",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_QTY: "Total Item(s) Qty",
  PRODUCT_INVENTORY_SALES_STORE: "Store",
  PRODUCT_INVENTORY_SALES_PRODUCTS: "Products",
  PRODUCT_INVENTORY_SALES_ORDER_NO: "Order No.",
  PRODUCT_INVENTORY_SALES_STORE_NAME: "Store Name",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_DISCOUNT: "Total Item(s) Discount",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_PRICE: "Total Item(s) Price",
  PRODUCT_INVENTORY_SALES_EXCEL_REPORT: "Excel Report",
  PRODUCT_INVENTORY_SALES_PDF_REPORT: "PDF Report",
  PRODUCT_INVENTORY_SALES_STATION_NAME: "Station Name",
  PRODUCT_INVENTORY_SALES_EXPORT_EXCEL_REPORT_SUMMARY:
    "Export Excel Report Summary",
  PRODUCT_INVENTORY_SALES_EXPORT_PDF_REPORT_SUMMARY:
    "Export PDF Report Summary",
};
export const inventorySaleArabic = {
  PRODUCT_INVENTORY_SALES_TEXT: "مبيعات المخزون",
  INVENTORY_SALE_S_TEXT: "مبيعات المخزون",
  PRODUCT_INVENTORY_SALES_ORDER_NO: "رقم الطلب",
  PRODUCT_INVENTORY_SALES_STORE_NAME: "اسم المتجر",
  PRODUCT_INVENTORY_SALES_FULFILLMENT_STATUS: "حالة التنفيذ",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_QTY: "إجمالي عدد العناصر",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_DISCOUNT: "إجمالي خصم العناصر",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_PRICE: "إجمالي سعر العناصر",
  PRODUCT_INVENTORY_SALES_FULFILLED_ONLY: "تم الوفاء فقط",
  PRODUCT_INVENTORY_SALES_IN_TRANSIT_ONLY: "في الانتقال فقط",
  PRODUCT_INVENTORY_SALES_START_DATE: "تاريخ البدء",
  PRODUCT_INVENTORY_SALES_END_DATE: "تاريخ الانتهاء",
  PRODUCT_INVENTORY_SALES_STATION: "المحطة",
  PRODUCT_INVENTORY_SALES_CARRIER_STATUS: "حالة الناقل",
  PRODUCT_INVENTORY_SALES_SALES_PERSON: "مندوب المبيعات",
  PRODUCT_INVENTORY_SALES_REGION_CITY: "المنطقة/المدينة",
  PRODUCT_INVENTORY_SALES_FULFILLED_ONLY: "تم الوفاء فقط",
  PRODUCT_INVENTORY_SALES_IN_TRANSIT_ONLY: "في الانتقال فقط",
  PRODUCT_INVENTORY_SALES_FULFILLMENT_STATUS: "حالة التنفيذ",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_QTY: "إجمالي عدد العناصر",
  PRODUCT_INVENTORY_SALES_STORE: "المتجر",
  PRODUCT_INVENTORY_SALES_PRODUCTS: "المنتجات",
  PRODUCT_INVENTORY_SALES_ORDER_NO: "رقم الطلب",
  PRODUCT_INVENTORY_SALES_STORE_NAME: "اسم المتجر",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_DISCOUNT: "إجمالي خصم العناصر",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_PRICE: "إجمالي سعر العناصر",
  PRODUCT_INVENTORY_SALES_EXCEL_REPORT: "تقرير Excel",
  PRODUCT_INVENTORY_SALES_PDF_REPORT: "تقرير PDF",
  PRODUCT_INVENTORY_SALES_STATION_NAME: "اسم المحطة",
  PRODUCT_INVENTORY_SALES_EXPORT_EXCEL_REPORT_SUMMARY: "تصدير ملخص تقرير Excel",
  PRODUCT_INVENTORY_SALES_EXPORT_PDF_REPORT_SUMMARY: "تصدير ملخص تقرير PDF",
};
export const inventorySaleChines = {
  PRODUCT_INVENTORY_SALES_TEXT: "库存销售",
  INVENTORY_SALE_S_TEXT: "库存销售",
  PRODUCT_INVENTORY_SALES_ORDER_NO: "订单号",
  PRODUCT_INVENTORY_SALES_STORE_NAME: "店铺名称",
  PRODUCT_INVENTORY_SALES_FULFILLMENT_STATUS: "履行状态",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_QTY: "总件数",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_DISCOUNT: "总折扣金额",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_PRICE: "总价格",
  PRODUCT_INVENTORY_SALES_FULFILLED_ONLY: "仅已完成",
  PRODUCT_INVENTORY_SALES_IN_TRANSIT_ONLY: "仅在途",
  PRODUCT_INVENTORY_SALES_START_DATE: "开始日期",
  PRODUCT_INVENTORY_SALES_END_DATE: "结束日期",
  PRODUCT_INVENTORY_SALES_STATION: "站点",
  PRODUCT_INVENTORY_SALES_CARRIER_STATUS: "承运状态",
  PRODUCT_INVENTORY_SALES_SALES_PERSON: "销售人员",
  PRODUCT_INVENTORY_SALES_REGION_CITY: "地区/城市",
  PRODUCT_INVENTORY_SALES_FULFILLED_ONLY: "仅已完成",
  PRODUCT_INVENTORY_SALES_IN_TRANSIT_ONLY: "仅在途",
  PRODUCT_INVENTORY_SALES_FULFILLMENT_STATUS: "履行状态",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_QTY: "总件数",
  PRODUCT_INVENTORY_SALES_STORE: "店铺",
  PRODUCT_INVENTORY_SALES_PRODUCTS: "产品",
  PRODUCT_INVENTORY_SALES_ORDER_NO: "订单号",
  PRODUCT_INVENTORY_SALES_STORE_NAME: "店铺名称",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_DISCOUNT: "总折扣金额",
  PRODUCT_INVENTORY_SALES_TOTAL_ITEMS_PRICE: "总价格",
  PRODUCT_INVENTORY_SALES_EXCEL_REPORT: "Excel报告",
  PRODUCT_INVENTORY_SALES_PDF_REPORT: "PDF报告",
  PRODUCT_INVENTORY_SALES_STATION_NAME: "站点名称",
  PRODUCT_INVENTORY_SALES_EXPORT_EXCEL_REPORT_SUMMARY: "导出Excel报告摘要",
  PRODUCT_INVENTORY_SALES_EXPORT_PDF_REPORT_SUMMARY: "导出PDF报告摘要",
};
