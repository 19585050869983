import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabContext } from "@mui/lab";
import { Box, Button, MenuItem, Tab, Tabs } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { styleSheet } from "../../assets/styles/style";
import { MenuComponent, useMenu } from "../../utilities/helpers/Helpers";

export default function DataGridTabs(props) {
  const {
    handleTabChange,
    tabData,
    handleFilterBtnOnClick,
    actionBtnMenuData,
    otherBtns,
    customBorderRadius,
    filterData,
  } = props;
  const location = useLocation();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const {
    anchorEl: actionMenuAnchorEl,
    open: actionMenuOpen,
    handleOpen: handleActionMenuOpen,
    handleClose: handleActionMenuClose,
  } = useMenu();

  const handleShowTabChildren = () => {
    if (Array.isArray(tabData)) {
      const selectedTab = tabData?.find(
        (dt) => dt.route === window.location.pathname
      );
      return selectedTab?.children;
    }
  };
  return (
    <>
      <Box
        className={"flex_between"}
        p={1}
        sx={{
          borderRadius: customBorderRadius || "8px 8px 0px 0px",
          border: "1px solid rgba(224, 224, 224, 1)",
          borderBottom: "none",
          background: "#F8F8F8",
        }}
      >
        <TabContext>
          <Tabs
            onChange={handleTabChange}
            value={location.pathname}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="agent-roster-tabs"
            sx={{
              ...styleSheet.customTabsUI,
              alignItems: "center",
              minHeight: 26,
            }}
          >
            {tabData?.map((item, index) => (
              <Tab
                key={index}
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  minWidth: "40px",
                }}
                label={item.label}
                value={item.route}
                to={item.route}
                component={Link}
              />
            ))}{" "}
          </Tabs>
        </TabContext>

        <Box className={"flex_center"} gap={1}>
          {otherBtns}
          {actionBtnMenuData && (
            <>
              <Button
                sx={{
                  ...styleSheet.filterIcon,
                  minWidth: "90px",
                }}
                variant="contained"
                aria-haspopup="true"
                disableElevation
                onClick={handleActionMenuOpen}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {LanguageReducer?.languageType?.ORDERS_ACTION}
              </Button>
              <MenuComponent
                anchorEl={actionMenuAnchorEl}
                open={actionMenuOpen}
                onClose={handleActionMenuClose}
              >
                {actionBtnMenuData.map((dt) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        dt.onClick();
                        handleActionMenuClose();
                      }}
                      disableRipple
                    >
                      {dt.title}
                    </MenuItem>
                  );
                })}
              </MenuComponent>
            </>
          )}
          {handleFilterBtnOnClick && (
            <Button
              sx={{ ...styleSheet.filterIconColord, minWidth: "90px" }}
              color="inherit"
              variant="outlined"
              onClick={handleFilterBtnOnClick}
              startIcon={<FilterAltOutlinedIcon fontSize="small" />}
            >
              {LanguageReducer?.languageType?.ORDERS_FILTER}
            </Button>
          )}
        </Box>
      </Box>
      <Box>{filterData}</Box>
      <Box>{handleShowTabChildren()}</Box>
    </>
  );
}
