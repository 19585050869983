export const pendingForReturnEnglish = {
  MY_CARRIER_PENDING_FOR_RETURN_TEXT: "Pending For Return",
  MY_CARRIER_PENDING_FOR_RETURN_ACTION: "Action",
  MY_CARRIER_PENDING_FOR_RETURN_CREATE_RETURN_REPORT: "Create Return Report",
  MY_CARRIER_PENDING_FOR_RETURN_FILTER: "Filter",
  MY_CARRIER_PENDING_FOR_RETURN_START_DATE: "Start Date",
  MY_CARRIER_PENDING_FOR_RETURN_END_DATE: "End Date",
  MY_CARRIER_PENDING_FOR_RETURN_ORDER_NO: "Order No.",
  MY_CARRIER_PENDING_FOR_RETURN_CUSTOMER: "Customer",
  MY_CARRIER_PENDING_FOR_RETURN_DRIVER: "Driver",
  MY_CARRIER_PENDING_FOR_RETURN_DRIVER_EMAIL: "Driver Email",
  MY_CARRIER_PENDING_FOR_RETURN_CARRIER_STATUS: "Carrier Status",
  MY_CARRIER_PENDING_FOR_RETURN_PAYMENT_STATUS: "Payment Status",
  MY_CARRIER_PENDING_FOR_RETURN_REMARKS: "Remarks",
};
export const pendingForReturnArabic = {
  MY_CARRIER_PENDING_FOR_RETURN_TEXT: "بانتظار الإرجاع",
  MY_CARRIER_PENDING_FOR_RETURN_ACTION: "إجراء",
  MY_CARRIER_PENDING_FOR_RETURN_CREATE_RETURN_REPORT: "إنشاء تقرير الإرجاع",
  MY_CARRIER_PENDING_FOR_RETURN_FILTER: "تصفية",
  MY_CARRIER_PENDING_FOR_RETURN_START_DATE: "تاريخ البداية",
  MY_CARRIER_PENDING_FOR_RETURN_END_DATE: "تاريخ النهاية",
  MY_CARRIER_PENDING_FOR_RETURN_ORDER_NO: "رقم الطلب",
  MY_CARRIER_PENDING_FOR_RETURN_CUSTOMER: "العميل",
  MY_CARRIER_PENDING_FOR_RETURN_DRIVER: "السائق",
  MY_CARRIER_PENDING_FOR_RETURN_DRIVER_EMAIL: "بريد السائق الإلكتروني",
  MY_CARRIER_PENDING_FOR_RETURN_CARRIER_STATUS: "حالة الناقل",
  MY_CARRIER_PENDING_FOR_RETURN_PAYMENT_STATUS: "حالة الدفع",
  MY_CARRIER_PENDING_FOR_RETURN_REMARKS: "ملاحaظات",
};
export const pendingForReturnChines = {
  MY_CARRIER_PENDING_FOR_RETURN_TEXT: "待退货",
  MY_CARRIER_PENDING_FOR_RETURN_ACTION: "操作",
  MY_CARRIER_PENDING_FOR_RETURN_CREATE_RETURN_REPORT: "创建退货报告",
  MY_CARRIER_PENDING_FOR_RETURN_FILTER: "筛选",
  MY_CARRIER_PENDING_FOR_RETURN_START_DATE: "开始日期",
  MY_CARRIER_PENDING_FOR_RETURN_END_DATE: "结束日期",
  MY_CARRIER_PENDING_FOR_RETURN_ORDER_NO: "订单号",
  MY_CARRIER_PENDING_FOR_RETURN_CUSTOMER: "客户",
  MY_CARRIER_PENDING_FOR_RETURN_DRIVER: "司机",
  MY_CARRIER_PENDING_FOR_RETURN_DRIVER_EMAIL: "司机电子邮件",
  MY_CARRIER_PENDING_FOR_RETURN_CARRIER_STATUS: "承运人状态",
  MY_CARRIER_PENDING_FOR_RETURN_PAYMENT_STATUS: "付款状态",
  MY_CARRIER_PENDING_FOR_RETURN_REMARKS: "备注",
};