import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Alert,
  Box,
  IconButton,
  Menu,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styleSheet } from "../../../../assets/styles/style";
import UpdateOutScanModal from "../../../../components/modals/myCarrierModals/UpdateOutscanModal";
import StatusBadge from "../../../../components/shared/statudBadge";
import UtilityClass from "../../../../utilities/UtilityClass";
import {
  EnumDeliveryTaskStatus,
  EnumNavigateState,
  EnumRoutesUrls,
} from "../../../../utilities/enum";
import Colors, {
  Danger,
  Success,
  Warning,
} from "../../../../utilities/helpers/Colors";
import {
  ClipboardIcon,
  CodeBox,
  DeleteButton,
  DescriptionBox,
  DescriptionBoxWithChild,
  DialerBox,
  MapButton,
  amountFormat,
  centerColumn,
  handleCopyToClipBoard,
  navbarHeight,
  rightColumn,
  useClientSubscriptionReducer,
  useGetWindowHeight,
  useNavigateSetState,
  usePagination,
} from "../../../../utilities/helpers/Helpers";
import UpdateOrderAddressLatLangModal from "../../../../components/modals/myCarrierModals/UpdateOrderAddressLatLangModal";
import useOpenStreetmapGetLatLng from "../../../../.reUseableComponents/CustomHooks/useOpenStreetmapGetLatLng";

function TasksList(props) {
  const {
    allDeliveryTask,
    getOrdersRef,
    loading,
    resetRowRef,
    setSelectedDeliveryTasks,
    getAllDeliveryTask,
    isFilterOpen,
  } = props;
  const clientSubscriptionData = useClientSubscriptionReducer();
  const { height: windowHeight } = useGetWindowHeight(clientSubscriptionData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUpdatelanLong, setopenUpdatelanLong] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const navigate = useNavigate();
  const [addressCoordinates, setAddressCoordinates] = useState(null);
  const { setNavigateState } = useNavigateSetState();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [slectedAddress, setSelectedAddress] = useState({
    open: false,
    isLoading: {},
    coordinates: null,
    selectedItem: null,
  });
  const handleCloseUpdateMap = () => {
    setSelectedAddress({
      open: false,
      isLoading: {},
      coordinates: null,
      selectedItem: null,
    });
  };
  //#region table colum
  const { getAddressCoordinates, isLoadingCoordinates, error } =
    useOpenStreetmapGetLatLng();
  const handleOpenModal = (row) => {
    const getCordinates = async () => {
      if (row) {
        try {
          const coordinates = await getAddressCoordinates(row.CityName);
          setSelectedAddress((prev) => ({
            open: true,
            isLoading: isLoadingCoordinates,
            coordinates: coordinates
              ? {
                  lat: parseFloat(coordinates?.lat)
                    ? parseFloat(coordinates?.lat)?.toFixed(5)
                    : 0,
                  lng: parseFloat(coordinates?.lng)
                    ? parseFloat(coordinates?.lng)?.toFixed(5)
                    : 0,
                }
              : {},
            selectedItem: row,
          }));
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    };
    getCordinates();
  };

  const columns = [
    {
      field: "OrderNo",
      // headerAlign: "center",
      // align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_ORDER_NO}
        </Box>
      ),
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            // display={"flex"}
            // flexDirection={"column"}
            // justifyContent={"center"}
            // sx={{ textAlign: "center", cursor: "pointer" }}
            disableRipple
          >
            <>
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                <CodeBox
                  title={params.row.OrderNo}
                  onClick={() => {
                    setNavigateState(EnumRoutesUrls.ORDERS, {
                      [EnumNavigateState.DELIVERY_TASKS.pageName]: {
                        [EnumNavigateState.DELIVERY_TASKS.state
                          .selectedOrderNo]: params.row.OrderNo,
                      },
                    });
                    handleCopyToClipBoard(params.row.OrderNo);
                  }}
                />
                <ClipboardIcon text={params.row.OrderNo} />
                <DescriptionBox
                  description={params.row.Remarks}
                  title="Remarks"
                />
              </Stack>
              <Box>
                <CodeBox title={params.row.TrackingNo} onClick={() => {}} />
              </Box>
            </>
          </Box>
        );
      },
    },
    {
      ...centerColumn,
      field: "TrackingStatus",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_TASKS_CARRIER_STATUS
          }
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <StatusBadge
              title={params.row.TrackingStatus}
              color="#1E1E1E;"
              bgColor="#EAEAEA"
            />
          </>
        );
      },
    },

    {
      field: "Customer",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_CUSTOMER}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexDirection={"column"} disableRipple>
            <>
              <Box sx={{ fontWeight: "bold" }}>
                {params.row.Customer}
                <DescriptionBoxWithChild>
                  <TableContainer>
                    <Table sx={{ minWidth: 275 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "110px",
                            }}
                            align="left"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                            }}
                          >
                            Mobile
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                            }}
                          >
                            Mobile 2
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "150px",
                            }}
                          >
                            Address
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={params.row.CustomerName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                            }}
                            align="left"
                          >
                            {params.row.Customer}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "7px", fontSize: "11px" }}
                            align="left"
                          >
                            <DialerBox phone={params.row.Mobile1} />
                          </TableCell>
                          <TableCell
                            sx={{ padding: "7px", fontSize: "11px" }}
                            align="left"
                          >
                            <DialerBox phone={params.row.Mobile2} />
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                              // width: "150px",
                            }}
                          >
                            {params.row.CustomerFullAddress}
                          </TableCell> */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DescriptionBoxWithChild>
              </Box>
            </>
          </Box>
        );
      },
    },

    {
      ...centerColumn,

      field: "Location",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_LOCATION}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ textAlign: "center" }} disableRipple>
              <>
                <Box>{params.row.RegionName}</Box>
                <Box>{params.row.CityName}</Box>
              </>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "DriverName",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_DRIVER}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ textAlign: "center" }} disableRipple>
              <>
                <Box>{params.row.DriverName}</Box>
                {/* <Box>{params.row.Mobile1}</Box>  */}
              </>
            </Box>
          </Box>
        );
      },
    },
    // {
    //   field: "Remarks",
    //   headerName: (
    //     <Box sx={{ fontWeight: "600" }}>
    //       {" "}
    //       {LanguageReducer?.languageType?.REMARKS_TEXT}
    //     </Box>
    //   ),
    //   flex: 1,
    //   renderCell: (params) => {
    //     return <Box>{params.row.Remarks}</Box>;
    //   },
    // },

    {
      ...centerColumn,
      field: "DeliveryTaskStatus",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_TASK_STATUS}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        let bgClr = Success;

        if (EnumDeliveryTaskStatus.Pending == params.row.DeliveryTaskStatusId) {
          bgClr = Danger;
        } else if (
          EnumDeliveryTaskStatus.Started == params.row.DeliveryTaskStatusId
        ) {
          bgClr = Warning;
        } else if (
          EnumDeliveryTaskStatus.Attempted == params.row.DeliveryTaskStatusId
        ) {
          bgClr = Colors.purple;
        }
        return (
          <StatusBadge
            title={params.row?.DeliveryTaskStatus}
            borderColor="rgba(0, 186, 119, 0.2)"
            color={
              EnumDeliveryTaskStatus.Completed ==
              params.row.DeliveryTaskStatusId
                ? "#fff;"
                : "#fff;"
            }
            bgColor={bgClr}
          />
        );
      },
    },
    {
      ...centerColumn,
      field: "OrderDate",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_ORDER_DATE}
        </Box>
      ),
      renderCell: (params) => (
        <Box>
          {UtilityClass.convertUtcToLocalAndGetDate(params.row.OrderDate)}
        </Box>
      ),
      flex: 1,
    },
    {
      ...rightColumn,
      field: "Amount",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_COD}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return <>{amountFormat(params.row.Amount)}</>;
      },
    },
    {
      field: "latLng",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_COORDINATES}
        </Box>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {row?.IsValidLatLng == 0 ? (
              <Box
                sx={{
                  color: `${Colors.danger}`,
                  fontSize: "10px",
                }}
              >
                Invalid location
              </Box>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "Action",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_ACTION}
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <>
            {row?.IsValidLatLng == 0 ? (
              <MapButton onClick={() => handleOpenModal(row)} />
            ) : (
              ""
            )}
          </>
        );
      },
      flex: 1,
    },
  ];
  //#endregion
  const [selectionModel, setSelectionModel] = useState([]);
  const { currentPage, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination(0, 10);
  const handleSelectedRow = (oNos) => {
    setSelectionModel(oNos);
    getOrdersRef.current = oNos;
    setSelectedDeliveryTasks(oNos);
  };
  useEffect(() => {
    if (resetRowRef && resetRowRef.current) {
      getOrdersRef.current = [];
      resetRowRef.current = false;
      setSelectionModel([]);
    }
  }, [resetRowRef.current]);
  const calculatedHeight = isFilterOpen
    ? windowHeight - navbarHeight - 222
    : windowHeight - navbarHeight - 67;
  return (
    <Box
      sx={{
        ...styleSheet.allOrderTable,
        height: calculatedHeight,
      }}
    >
      <DataGrid
        loading={loading}
        getRowHeight={() => "auto"}
        headerHeight={40}
        sx={{
          fontFamily: "'Lato Regular', 'Inter Regular', 'Arial' !important",
          fontSize: "12px",
          fontWeight: "500",
        }}
        rows={allDeliveryTask?.list ? allDeliveryTask?.list : []}
        getRowId={(row) => row.OrderNo}
        columns={columns}
        disableSelectionOnClick
        pagination
        page={currentPage}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 15, 25]}
        paginationMode="client"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={(oNo) => handleSelectedRow(oNo)}
      />
      <Menu
        anchorEl={anchorEl}
        id="power-search-menu"
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      ></Menu>
      {openUpdate && (
        <UpdateOutScanModal
          open={openUpdate}
          setOpen={setOpenUpdate}
          {...props}
        />
      )}

      {slectedAddress.open && (
        <UpdateOrderAddressLatLangModal
          open={slectedAddress.open}
          setOpen={() => handleCloseUpdateMap()}
          slectedAddress={slectedAddress}
          setSelectedAddress={setSelectedAddress}
          getAllDeliveryTask={getAllDeliveryTask}

          // addressCoordinates={addressCoordinates}
        />
      )}
    </Box>
  );
}
export default TasksList;
