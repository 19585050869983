export const notificationEnglish = {
  SETTING_NOTIFICATION_SPECIFIERS: "Specifiers",
  SETTING_NOTIFICATION_ORDER_NO: "orderNo",
  SETTING_NOTIFICATION_CARRIER_TRACKING: "carrierTracking",
  SETTING_NOTIFICATION_CUSTOMER_NAME: "customerName",
  SETTING_NOTIFICATION_AMOUNT: "amount",
  SETTING_NOTIFICATION_STATUS: "status",
};
export const notificationArabic = {
  SETTING_NOTIFICATION_SPECIFIERS: "محددات",
  SETTING_NOTIFICATION_ORDER_NO: "رقم الطلب",
  SETTING_NOTIFICATION_CARRIER_TRACKING: "تتبع الناقل",
  SETTING_NOTIFICATION_CUSTOMER_NAME: "اسم العميل",
  SETTING_NOTIFICATION_AMOUNT: "المبلغ",
  SETTING_NOTIFICATION_STATUS: "الحالة",
};
export const notificationChines = {
  SETTING_NOTIFICATION_SPECIFIERS: "说明符",
  SETTING_NOTIFICATION_ORDER_NO: "订单号",
  SETTING_NOTIFICATION_CARRIER_TRACKING: "承运人追踪",
  SETTING_NOTIFICATION_CUSTOMER_NAME: "客户名称",
  SETTING_NOTIFICATION_AMOUNT: "金额",
  SETTING_NOTIFICATION_STATUS: "状态",
};
