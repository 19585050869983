import { Box } from "@mui/material";
import React from "react";
import DataGridProComponent from "../../../../.reUseableComponents/DataGrid/DataGridProComponent";
import { styleSheet } from "../../../../assets/styles/style";
import { EnumChangeFilterModelApiUrls } from "../../../../utilities/enum";
import {
    ActionButtonCustom,
    DataGridAvatar,
    useGetWindowHeight
} from "../../../../utilities/helpers/Helpers";

const PriceCalculatorList = (props) => {
  const { carrierData, carriersCount, isFilterOpen, isLoading } = props;
  const { height: windowHeight } = useGetWindowHeight();
  console.log(carrierData);
  const columns = [
    {
      field: "carrier",
      minWidth: 160,
      flex: 1,
      headerName: <Box sx={{ fontWeight: "600" }}>{"Carrier"}</Box>,
      renderCell: ({ row }) => {
        return (
          <Box>
            <DataGridAvatar src={row.CarrierImage} name={row.Name} />
          </Box>
        );
      },
    },
    {
      field: "Action",
      minWidth: 200,
      flex: 1,
      headerName: <Box sx={{ fontWeight: "600" }}>{"Action"}</Box>,
      renderCell: ({ row }) => {
        return (
          <>
            <ActionButtonCustom
              //   onClick={}
              sx={{
                ...styleSheet.integrationactivatedButton,
                width: "18%",
                height: "28px",
                borderRadius: "4px",
              }}
              variant="contained"
              label={"Add Carrier"}
            ></ActionButtonCustom>
          </>
        );
      },
    },
  ];
  const calculatedHeightTable = isFilterOpen
    ? windowHeight - 169 - 85.5
    : windowHeight - 169;
  return (
    <Box
      sx={{
        ...styleSheet.allOrderTable,
        height: calculatedHeightTable,
        paddingBottom: "20px",
      }}
    >
      <DataGridProComponent
        rowPadding={8}
        rows={carrierData}
        columns={columns}
        loading={isLoading}
        headerHeight={40}
        getRowId={(row) => row.RowNum}
        checkboxSelection={false}
        disableSelectionOnClick
        rowsCount={carriersCount}
        paginationChangeMethod={carrierData}
        paginationMethodUrl={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.url}
        defaultRowsPerPage={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length}
        height={calculatedHeightTable}
      />
    </Box>
  );
};

export default PriceCalculatorList;
