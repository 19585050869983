export const saleChannelEnglish = {
  INTEGRATION_SALE_CHANNEL_S_TEXT: "Sale Channel(s)",
  INTEGRATION_SALE_CHANNEL_TEXT: "Sale Channels",
  INTEGRATION_SALE_CHANNEL_ALL: "All",
  INTEGRATION_SALE_CHANNEL_ACTIVE: "Active",
  INTEGRATION_SALE_CHANNEL_ACTIVATE: "Activate",
  INTEGRATION_SALE_CHANNEL_INACTIVE: "Inactive",
  INTEGRATION_SALE_CHANNEL_ADD_SALE_CHANNEL: "Add Sale Channel",
  INTEGRATION_SALE_CHANNEL_START_DATE: "Start Date",
  INTEGRATION_SALE_CHANNEL_END_DATE: "End Date",
  INTEGRATION_SALE_CHANNEL_SALE_CHANNEL_NAME: "Sale Channel Name",
  INTEGRATION_SALE_CHANNEL_STORE_NAME: "Store Name",
  INTEGRATION_SALE_CHANNEL_SALE_CHANNEL_TYPE: "Sale Channel(s) Type",
  INTEGRATION_SALE_CHANNEL_ALLOW_TO_DISPLAY: "Allow To Display In Sale Channel",
  INTEGRATION_SALE_CHANNEL_STORE_NAME: "Store Name",
  INTEGRATION_SALE_CHANNEL_DISPLAY_NAME: "Display Name",
  INTEGRATION_SALE_CHANNEL_CREATE_DATE: "Create Date",
  INTEGRATION_SALE_CHANNEL_STATUS: "Status",
  INTEGRATION_SALE_CHANNEL_ACTION: "Action",
  INTEGRATION_SALE_CHANNEL_DEFAULT: "Default",
};
export const saleChannelArabic = {
  INTEGRATION_SALE_CHANNEL_S_TEXT: "قناة البيع",
  INTEGRATION_SALE_CHANNEL_TEXT: "قنوات البيع",
  INTEGRATION_SALE_CHANNEL_ALL: "الكل",
  INTEGRATION_SALE_CHANNEL_ACTIVE: "نشط",
  INTEGRATION_SALE_CHANNEL_INACTIVE: "غير نشط",
  INTEGRATION_SALE_CHANNEL_ACTIVATE: "تفعيل",
  INTEGRATION_SALE_CHANNEL_ADD_SALE_CHANNEL: "إضافة قناة بيع",
  INTEGRATION_SALE_CHANNEL_START_DATE: "تاريخ البدء",
  INTEGRATION_SALE_CHANNEL_END_DATE: "تاريخ الانتهاء",
  INTEGRATION_SALE_CHANNEL_SALE_CHANNEL_NAME: "اسم قناة البيع",
  INTEGRATION_SALE_CHANNEL_STORE_NAME: "اسم المتجر",
  INTEGRATION_SALE_CHANNEL_SALE_CHANNEL_TYPE: "نوع قنوات البيع",
  INTEGRATION_SALE_CHANNEL_ALLOW_TO_DISPLAY: "السماح بالعرض في قناة البيع",
  INTEGRATION_SALE_CHANNEL_DISPLAY_NAME: "اسم العرض",
  INTEGRATION_SALE_CHANNEL_CREATE_DATE: "تاريخ الإنشاء",
  INTEGRATION_SALE_CHANNEL_STATUS: "الحالة",
  INTEGRATION_SALE_CHANNEL_ACTION: "الإجراء",
  INTEGRATION_SALE_CHANNEL_DEFAULT: "افتراضي",
};

export const saleChannelChinese = {
  INTEGRATION_SALE_CHANNEL_S_TEXT: "销售渠道",
  INTEGRATION_SALE_CHANNEL_TEXT: "销售渠道",
  INTEGRATION_SALE_CHANNEL_ALL: "全部",
  INTEGRATION_SALE_CHANNEL_ACTIVE: "活跃",
  INTEGRATION_SALE_CHANNEL_INACTIVE: "不活跃",
  INTEGRATION_SALE_CHANNEL_ACTIVATE: "激活",
  INTEGRATION_SALE_CHANNEL_ADD_SALE_CHANNEL: "添加销售渠道",
  INTEGRATION_SALE_CHANNEL_START_DATE: "开始日期",
  INTEGRATION_SALE_CHANNEL_END_DATE: "结束日期",
  INTEGRATION_SALE_CHANNEL_SALE_CHANNEL_NAME: "销售渠道名称",
  INTEGRATION_SALE_CHANNEL_STORE_NAME: "店铺名称",
  INTEGRATION_SALE_CHANNEL_SALE_CHANNEL_TYPE: "销售渠道类型",
  INTEGRATION_SALE_CHANNEL_ALLOW_TO_DISPLAY: "允许在销售渠道显示",
  INTEGRATION_SALE_CHANNEL_DISPLAY_NAME: "显示名称",
  INTEGRATION_SALE_CHANNEL_CREATE_DATE: "创建日期",
  INTEGRATION_SALE_CHANNEL_STATUS: "状态",
  INTEGRATION_SALE_CHANNEL_ACTION: "操作",
  INTEGRATION_SALE_CHANNEL_DEFAULT: "默认",
};
