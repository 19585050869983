import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import {
  GetAllCarrierTrackingStatusForSelection,
  GetAllDeliveryTask,
  GetAllDeliveryTaskStatusForSelection,
  GetDriversForSelection,
  GetStoresForSelection,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import BatchOutScanModal from "../../../components/modals/myCarrierModals/BatchOutScanModal";
import BatchRevertModal from "../../../components/modals/myCarrierModals/BatchRevertModal";
import GeneralTabBar from "../../../components/shared/tabsBar";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  errorNotification,
  warningNotification,
} from "../../../utilities/toast";
import TasksList from "./tasksList";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
import DataGridTabs from "../../../.reUseableComponents/DataGridTabs/DataGridTabs";
import ButtonComponent from "../../../.reUseableComponents/Buttons/ButtonComponent";
import { EnumOptions } from "../../../utilities/enum";
import initialStateFilter from "../../../utilities/filterState";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import { useGetAllCountries } from "../../../utilities/helpers/Helpers";
import {
  useGetAllCitiesByRegionIds,
  useGetAllRegionbyCountryIds,
} from "../../../utilities/helpers/HelpersFilter";
import LabelWithCheckBox from "../../../.reUseableComponents/TextField/LabelWithCheckBox";
import DeliveryTaskMapComponent from "./MapComponent";
import Colors from "../../../utilities/helpers/Colors";
import ExistingNoteModal from "../../../components/modals/myCarrierModals/ExistingNoteModal";

const EnumTabFilter = Object.freeze({
  All: "/delivery-tasks",
  Unassigned: "/delivery-tasks/unassigned",
  Assigned: "/delivery-tasks/assigned",
});
const EnumDashboardAction = {
  BATCHOUTSCAN: "BatchOutScan",
  BATCHREVERT: "BatchRevert",
  EXISTINGNOTEMODAL: "ExistingNoteModal",
};
function DeliveryTasks(props) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [allDeliveryTask, setAllDeliveryTask] = useState([]);
  const [isAllListLoading, setIsAllListLoading] = useState([]);
  const [open, setOpen] = useState(false);
  const [revertOpen, setRevertOpen] = useState(false);
  const [allDrivers, setAllDrivers] = useState([]);
  const [driverId, setDriverId] = useState([]);
  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);
  const [selectedDeliveryTasks, setSelectedDeliveryTasks] = useState([]);
  const [storesForSelection, setStoresForSelection] = useState([]);
  const [storeId, setStoreId] = useState(initialStateFilter.multiple);
  const [allCarrierTrackingStatus, setAllCarrierTrackingStatus] = useState([]);
  const [selectedCarrierTrackingStatus, setSelectedCarrierTrackingStatus] =
    useState([]);
  const [driverIncludeChecked, setDriverIncludeChecked] = useState(true);

  const [regionIncludeChecked, setRegionIncludeChecked] = useState(true);
  const [cityIncludeChecked, setCityIncludeChecked] = useState(true);
  const [StatusTaskForSelection, setStatusTaskForSelection] = useState([]);
  const [deliveryTaskStatusId, setdeliveryTaskStatusId] = useState([]);
  const [openExistingNote, setopenExistingNote] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [defaultCard, setDefaultCard] = useState({
    total: 0,
    totalRegion: 0,
    assignedShipment: 0,
    unassigned: 0,
  });

  const { register, reset, control } = useForm({
    defaultValues: { startDate: null, endDate: null },
  });
  let defaultValues = {
    startDate: null,
    endDate: null,
    driverAssignedStatus: 0,
  };
  const [isFilterReset, setIsFilterReset] = useState(false);
  const mapChildRef = useRef(null);
  // Method to reset the child state

  const LanguageReducer = useSelector((state) => state.LanguageReducer);

  let getDriversForSelection = async () => {
    let res = await GetDriversForSelection();

    if (res.data.result != null) {
      setAllDrivers(res.data.result);
    }
  };
  const handleToggleMap = () => {
    setShowMap(!showMap);
  };
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
  } = useDateRangeHook();
  const getFiltersFromState = () => {
    let search = "";
    let filters = {
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: 1000,
        search: search,
        sortDir: "desc",
        sortCol: 0,
      },
      driverAssignedStatus: driverAssignedStatus,
      StoreIds: storeId?.map((data) => data.storeId).toString(),
      CarrierTrackingStatusIds: selectedCarrierTrackingStatus
        ?.map((data) => data.carrierTrackingStatusId)
        .toString(),
      CountryIds: selectedCountryIds?.map((data) => data.countryId).toString(),
      RegionIds: selectedRegions?.map((data) => data.id).toString(),
      CityIds: selectedCities?.map((data) => data.id).toString(),
      DriverIds: driverId?.map((data) => data?.DriverId).toString(),
      IncludeDriver: driverIncludeChecked,
      IncludeRegion: regionIncludeChecked,
      IncludeCity: cityIncludeChecked,
      DeliveryTaskStatusIds: deliveryTaskStatusId
        ?.map((data) => data?.deliveryTaskStatusId)
        .toString(),
    };
    return filters;
  };
  const getIncludeExcludeRegionIds = () => {
    let newVal = selectedRegions;
    if (!regionIncludeChecked) {
      newVal = allRegions.filter(
        (region) =>
          !selectedRegions.some((selected) => region.id === selected.id)
      );
    }
    return newVal.map((country) => country.id).join(",");
  };
  const getIncludeExcludeCityIds = () => {
    let newVal = selectedCities;
    if (!cityIncludeChecked) {
      newVal = allCities.filter(
        (region) =>
          !selectedCities.some((selected) => region.id === selected.id)
      );
    }
    return newVal.map((cty) => cty.id).join(",");
  };
  let getStoresForSelection = async () => {
    let res = await GetStoresForSelection();
    // console.log("getStoresForSelection", res.data);
    if (res.data.result != null) {
      setStoresForSelection(res.data.result);
    }
  };
  let getStatusTaskForSelection = async () => {
    let res = await GetAllDeliveryTaskStatusForSelection();
    // console.log("getStoresForSelection", res.data);
    if (res.data.result != null) {
      setStatusTaskForSelection(res.data.result);
    }
  };
  const getAllCarrierTrackingStatusForSelection = async () => {
    try {
      const response = await GetAllCarrierTrackingStatusForSelection();
      setAllCarrierTrackingStatus(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const { countries, selectedCountryIds, setSelectedCountryIds } =
    useGetAllCountries();
  const {
    allCities,
    handleGetAllCitiesByIds,
    selectedCities,
    setSelectedCities,
    setAllCities,
  } = useGetAllCitiesByRegionIds();
  const {
    allRegions,
    handleGetAllRegionsByIds,
    selectedRegions,
    setSelectedRegions,
    setAllRegions,
  } = useGetAllRegionbyCountryIds();
  useEffect(() => {
    if (selectedCountryIds.length > 0) {
      const countryIds = selectedCountryIds
        .map((country) => country.countryId)
        .join(",");

      handleGetAllRegionsByIds(countryIds);
    } else {
      setAllRegions([]);
      setAllCities([]);
    }
  }, [selectedCountryIds]);
  const handleDefaultCardSet = () => {
    const allDTask = allDeliveryTask?.list;
    if (allDTask) {
      // Grouping delivery tasks by RegionName and calculating count
      const groupedByRegionCount = allDTask?.reduce((acc, task) => {
        const { RegionName } = task;
        if (!acc[RegionName]) {
          acc[RegionName] = { totalRegion: 0 };
        }
        acc[RegionName].totalRegion++;
        return acc;
      }, {});
      const regionCount = Object.keys(groupedByRegionCount).length;
      // Update the total state
      setDefaultCard((prevState) => ({
        ...prevState,
        total: allDeliveryTask?.TotalCount,
        unassigned: allDTask?.filter((x) => x.DriverName == "").length,
        assignedShipment: allDTask?.filter((x) => x.DriverName != "").length,
        totalRegion: regionCount,
      }));
    }
  };
  useEffect(() => {
    if (selectedRegions.length > 0) {
      // let regionIds = selectedRegions.map((country) => country.id).join(",");
      handleGetAllCitiesByIds(getIncludeExcludeRegionIds());
      setAllCities([]);
    } else {
      setAllCities([]);
    }
  }, [selectedRegions, regionIncludeChecked]);
  let getAllDeliveryTask = async () => {
    setIsAllListLoading(true);
    let params = getFiltersFromState();
    let res = await GetAllDeliveryTask(params);
    if (res.data.result !== null) {
      setAllDeliveryTask(res.data.result);
    }
    setIsAllListLoading(false);
    resetRowRef.current = false;
  };
  useEffect(() => {
    getAllCarrierTrackingStatusForSelection();
    getStoresForSelection();
    getDriversForSelection();
    getStatusTaskForSelection();
    getAllDeliveryTask();
  }, []);
  useEffect(() => {
    if (isFilterReset) {
      getAllDeliveryTask();
    }
  }, [isFilterReset]);
  useEffect(() => {
    handleDefaultCardSet();
  }, [allDeliveryTask]);
  const [isfilterClear, setIsfilterClear] = useState(false);
  const [isTabFilter, setIsTabFilter] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [driverAssignedStatus, setDriverAssignedStatus] = useState(
    defaultValues.driverAssignedStatus
  );

  useEffect(() => {
    if (isfilterClear) {
      getAllDeliveryTask();
      resetDates();
      setIsfilterClear(false);
    }
  }, [isfilterClear]);
  useEffect(() => {
    if (isTabFilter) {
      getAllDeliveryTask();
      setIsTabFilter(false);
    }
  }, [driverAssignedStatus]);
  const handleFilterRest = () => {
    setDriverAssignedStatus(defaultValues.driverAssignedStatus);
    setStartDate(defaultValues.startDate);
    setEndDate(defaultValues.endDate);
    setSelectedCarrierTrackingStatus(initialStateFilter.multiple);
    setSelectedCountryIds(initialStateFilter.multiple);
    setSelectedRegions(initialStateFilter.multiple);
    setSelectedCities(initialStateFilter.multiple);
    setStoreId(initialStateFilter.multiple);
    setDriverId(initialStateFilter.multiple);
    setdeliveryTaskStatusId(initialStateFilter.multiple);

    if (mapChildRef.current) {
      mapChildRef?.current.Rest();
    }
  };
  const handleFilterClear = async () => {
    handleFilterRest();
    setIsfilterClear(true);
  };
  const handleTabChange = (event, filterValue) => {
    handleFilterRest();
    setIsFilterOpen(false);
    if (filterValue === EnumTabFilter.All) {
      setDriverAssignedStatus(0); //0 for all shipments
      setIsShowFilter(true);
    } else if (filterValue == EnumTabFilter.Unassigned) {
      setDriverAssignedStatus(1); //1 for unassigned shipments
      setIsShowFilter(false);
    } else if (filterValue == EnumTabFilter.Assigned) {
      setDriverAssignedStatus(2); //2 for Assigned shipments
      setIsShowFilter(false);
    }
    resetRowRef.current = true;
    setIsTabFilter(true);
  };
  const isAllUnassignedOrdersAndShowMsg = () => {
    let isUnAssign = false;
    if (selectedDeliveryTasks.length > 0) {
      let selectedRowsData = allDeliveryTask?.list?.filter((item) =>
        selectedDeliveryTasks.includes(item.OrderNo)
      );
      const orderNosWithoutDriver = selectedRowsData
        .filter((item) => item.DriverName !== "") // Filter objects with empty DriverName
        .map((item) => item.OrderNo); // Extract OrderNo values

      if (orderNosWithoutDriver.length > 0) {
        const commaSeparatedOrderNos = orderNosWithoutDriver.join(", ");
        errorNotification(
          "Please remove the below-mentioned orders from the selection because they have already been in the Delivery note. " +
            commaSeparatedOrderNos
        );
      } else {
        isUnAssign = true;
      }
    } else {
      warningNotification(
        LanguageReducer?.languageType?.MUST_SELECT_SINGLE_ROW_WARNING_TOAST
      );
    }
    return isUnAssign;
  };
  const getRoutComponent = (
    <TasksList
      loading={isAllListLoading}
      allDeliveryTask={allDeliveryTask}
      getOrdersRef={getOrdersRef}
      resetRowRef={resetRowRef}
      getAllDeliveryTask={getAllDeliveryTask}
      setSelectedDeliveryTasks={setSelectedDeliveryTasks}
      isFilterOpen={isFilterOpen}
    />
  );
  const handleBatchRevert = () => {
    const selectedTrNos = getOrdersRef.current;
    if (selectedTrNos.length === 0) {
      warningNotification(
        LanguageReducer?.languageType?.MUST_SELECT_SINGLE_ROW_WARNING_TOAST
      );
    } else {
      setRevertOpen(true);
    }
  };
  const handleResetOutscanModel = () => {
    if (mapChildRef.current) {
      mapChildRef?.current.Rest();
    }
  };
  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        {" "}
        <DataGridTabs
          handleTabChange={handleTabChange}
          tabData={[
            {
              label:
                LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_All,
              route: EnumTabFilter.All,
            },
            {
              label:
                LanguageReducer?.languageType
                  ?.MY_CARRIER_DELIVERY_TASKS_ASSIGNED,
              route: EnumTabFilter.Assigned,
            },
            {
              label:
                LanguageReducer?.languageType
                  ?.MY_CARRIER_DELIVERY_TASKS_UNASSIGNED,
              route: EnumTabFilter.Unassigned,
            },
          ]}
          actionBtnMenuData={[
            {
              title:
                LanguageReducer?.languageType
                  ?.MY_CARRIER_DELIVERY_TASKS_BATCH_REVERT,
              onClick: () => {
                handleBatchRevert();
              },
            },
          ]}
          otherBtns={
            <>
              {selectedDeliveryTasks.length > 0 && (
                <>
                  <ButtonComponent
                    title={
                      LanguageReducer?.languageType
                        ?.MY_CARRIER_DELIVERY_TASKS_CREATE_DELIVERY_NOTE
                    }
                    onClick={() => {
                      if (!isAllUnassignedOrdersAndShowMsg()) {
                        return;
                      } else {
                        setOpen(true);
                      }
                    }}
                  />
                </>
              )}
              {selectedDeliveryTasks.length > 0 && (
                <>
                  <ButtonComponent
                    title={
                      LanguageReducer?.languageType
                        ?.MY_CARRIER_DELIVERY_TASKS_ADD_TO_EXISTING_NOTE
                    }
                    onClick={() => {
                      if (!isAllUnassignedOrdersAndShowMsg()) {
                        return;
                      } else {
                        setopenExistingNote(true);
                      }
                    }}
                  />
                </>
              )}
              <ButtonComponent
                bg={Colors.succes}
                title={
                  showMap
                    ? LanguageReducer?.languageType
                        ?.MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_GRID
                    : LanguageReducer?.languageType
                        ?.MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_MAP
                }
                onClick={() => {
                  handleToggleMap();
                }}
              />
            </>
          }
          handleFilterBtnOnClick={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
        />
        {isFilterOpen ? (
          <Table
            sx={{ ...styleSheet.generalFilterArea }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <Grid container spacing={2} sx={{ p: "15px" }}>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_START_DATE
                        }
                      </InputLabel>

                      <CustomReactDatePickerInputFilter
                        value={startDate}
                        onClick={(date) => setStartDate(date)}
                        size="small"
                        isClearable
                        maxDate={UtilityClass.todayDate()}

                        // inputProps={{ style: { padding: "4px 5px" } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_END_DATE
                        }
                      </InputLabel>
                      <CustomReactDatePickerInputFilter
                        value={endDate}
                        onClick={(date) => setEndDate(date)}
                        size="small"
                        minDate={startDate}
                        disabled={!startDate ? true : false}
                        isClearable
                        maxDate={UtilityClass.todayDate()}

                        // inputProps={{ style: { padding: "4px 5px" } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    <Grid>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_STORE
                        }
                      </InputLabel>
                      <SelectComponent
                        multiple={true}
                        name="reason"
                        height={28}
                        options={storesForSelection}
                        value={storeId}
                        optionLabel={EnumOptions.STORE.LABEL}
                        optionValue={EnumOptions.STORE.VALUE}
                        getOptionLabel={(option) => option?.storeName}
                        onChange={(e, val) => {
                          setStoreId(val);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    <Grid>
                      <LabelWithCheckBox
                        isShowSwitch={true}
                        height={28}
                        checked={driverIncludeChecked}
                        checkedLabel={
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_INCLUDE
                        }
                        unCheckedLabel={
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_EXCLUDE
                        }
                        inputLabel={
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_DRIVER
                        }
                        onChange={(e) =>
                          setDriverIncludeChecked(e.target.checked)
                        }
                      />
                      <SelectComponent
                        multiple={true}
                        name="reason"
                        options={allDrivers}
                        height={28}
                        value={driverId}
                        optionLabel={EnumOptions.DRIVER.LABEL}
                        optionValue={EnumOptions.DRIVER.VALUE}
                        getOptionLabel={(option) => option?.DriverName}
                        onChange={(e, val) => {
                          setDriverId(val);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    <InputLabel
                      sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                    >
                      {
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_CARRIER_TRACKING_STATUS
                      }
                    </InputLabel>
                    <SelectComponent
                      multiple={true}
                      height={28}
                      optionLabel={EnumOptions.CARRIER_TRACKING_STATUS.LABEL}
                      optionValue={EnumOptions.CARRIER_TRACKING_STATUS.VALUE}
                      name="carrierStatus"
                      options={allCarrierTrackingStatus}
                      value={selectedCarrierTrackingStatus}
                      getOptionLabel={(option) => option.text}
                      onChange={(e, val) => {
                        setSelectedCarrierTrackingStatus(val);
                      }}
                    />
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    <InputLabel
                      sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                    >
                      {
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_DELIVERY_TASK_STATUS
                      }
                    </InputLabel>
                    <SelectComponent
                      multiple={true}
                      height={28}
                      optionLabel={EnumOptions.DELIVRRY_TASK_STATUS.LABEL}
                      optionValue={EnumOptions.DELIVRRY_TASK_STATUS.VALUE}
                      name="carrierStatus"
                      options={StatusTaskForSelection}
                      value={deliveryTaskStatusId}
                      getOptionLabel={(option) => option.text}
                      onChange={(e, val) => {
                        setdeliveryTaskStatusId(val);
                      }}
                    />
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    <InputLabel
                      sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                    >
                      {
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_COUNTRY
                      }
                    </InputLabel>
                    <SelectComponent
                      multiple={true}
                      name="country"
                      height={28}
                      options={countries}
                      value={selectedCountryIds}
                      optionLabel={EnumOptions.COUNTRY.LABEL}
                      optionValue={EnumOptions.COUNTRY.VALUE}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, val) => {
                        setSelectedCountryIds(val);
                      }}
                    />
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    {/* <InputLabel
                          sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                        >
                          {"Region"}
                        </InputLabel> */}
                    <LabelWithCheckBox
                      isShowSwitch={true}
                      checked={regionIncludeChecked}
                      checkedLabel={
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_INCLUDE
                      }
                      unCheckedLabel={
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_EXCLUDE
                      }
                      inputLabel="Region/City"
                      onChange={(e) =>
                        setRegionIncludeChecked(e.target.checked)
                      }
                      disabled={selectedCountryIds.length == 0}
                    />
                    <SelectComponent
                      disabled={selectedCountryIds.length == 0}
                      multiple={true}
                      name="region"
                      height={28}
                      options={allRegions}
                      value={selectedRegions}
                      optionLabel={EnumOptions.REGIONS.LABEL}
                      optionValue={EnumOptions.REGIONS.VALUE}
                      getOptionLabel={(option) => option.text}
                      onChange={(e, val) => {
                        setSelectedRegions(val);
                      }}
                    />
                  </Grid>
                  <Grid item minWidth={"16.66%"}>
                    <LabelWithCheckBox
                      isShowSwitch={true}
                      checked={cityIncludeChecked}
                      checkedLabel={
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_INCLUDE
                      }
                      unCheckedLabel={
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_TASKS_EXCLUDE
                      }
                      inputLabel="Area/Neighbourhood"
                      onChange={(e) => setCityIncludeChecked(e.target.checked)}
                      disabled={selectedRegions.length == 0}
                    />
                    <SelectComponent
                      disabled={selectedRegions.length == 0}
                      multiple={true}
                      name="city"
                      height={28}
                      options={allCities}
                      value={selectedCities}
                      optionLabel={EnumOptions.CITY.LABEL}
                      optionValue={EnumOptions.CITY.VALUE}
                      getOptionLabel={(option) => option.text}
                      onChange={(e, val) => {
                        setSelectedCities(val);
                      }}
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Stack
                      alignItems="flex-end"
                      direction="row"
                      spacing={1}
                      sx={{ ...styleSheet.filterButtonMargin, height: "100%" }}
                    >
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          handleFilterClear();
                        }}
                      >
                        {LanguageReducer?.languageType?.CLEAR_FILTER}
                      </Button>
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        variant="contained"
                        onClick={() => {
                          getAllDeliveryTask();
                        }}
                      >
                        {LanguageReducer?.languageType?.FILTER}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                {/* <Grid sx={{ display: "flex", justifyContent: "end" }}>
                  <Grid sx={{ p: "6px" }}>
                    <Stack alignItems="flex-end" direction="row" spacing={1}>
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          handleFilterClear();
                        }}
                      >
                        {LanguageReducer?.languageType?.CLEAR_FILTER_TEXT}
                      </Button>
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        variant="contained"
                        onClick={() => {
                          getAllDeliveryTask();
                        }}
                      >
                        {"Filter"}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid> */}
              </TableRow>
            </TableHead>
          </Table>
        ) : null}
        {showMap ? (
          <DeliveryTaskMapComponent
            mapChildRef={mapChildRef}
            setSelectedDeliveryTasks={setSelectedDeliveryTasks}
            allDeliveryTask={allDeliveryTask}
            setAllDeliveryTask={setAllDeliveryTask}
            defaultCard={defaultCard}
          />
        ) : (
          <Routes>
            <Route path="/" element={getRoutComponent} />
            <Route path="/unassigned" element={getRoutComponent} />
            <Route path="/assigned" element={getRoutComponent} />
          </Routes>
        )}
      </div>

      {open && (
        <BatchOutScanModal
          open={open}
          setOpen={setOpen}
          {...props}
          orderNosData={selectedDeliveryTasks}
          allDrivers={allDrivers}
          getAllDeliveryTask={getAllDeliveryTask}
          resetRowRef={resetRowRef}
          allDeliveryTask={allDeliveryTask}
          handleResetOutscanModel={handleResetOutscanModel}
        />
      )}
      {openExistingNote && (
        <ExistingNoteModal
          open={openExistingNote}
          setOpen={setopenExistingNote}
          getAllDeliveryTask={getAllDeliveryTask}
          handleResetOutscanModel={handleResetOutscanModel}
          orderNosData={selectedDeliveryTasks}
          resetRowRef={resetRowRef}
          allDeliveryTask={allDeliveryTask}
        />
      )}
      {revertOpen && (
        <BatchRevertModal
          open={revertOpen}
          setOpen={setRevertOpen}
          {...props}
          orderNosData={getOrdersRef.current}
          allDrivers={allDrivers}
          getAllDeliveryTask={getAllDeliveryTask}
          resetRowRef={resetRowRef}
        />
      )}
    </Box>
  );
}
export default DeliveryTasks;
