export const inventoryEnglish = {
  PRODUCT_INVENTORY_TEXT: "Inventory",
  PRODUCT_INVENTORY_AVAILABLE_ONLY: "Available Only",
  PRODUCT_INVENTORY_SYNC_INVENTORY: "Sync Inventory",
  PRODUCT_INVENTORY_ACTION: "Action",
  PRODUCT_INVENTORY_TITLE: "Title",
  PRODUCT_INVENTORY_SKU: "SKU",
  PRODUCT_INVENTORY_VARIANT: "Variant",
  PRODUCT_INVENTORY_STORE_NAME: "Store Name",
  PRODUCT_INVENTORY_PRODUCT_STATION: "Product Station",
  PRODUCT_INVENTORY_SALE_PRICE: "Sale Price",
  PRODUCT_INVENTORY_LOW_QTY: "Low Qty",
  PRODUCT_INVENTORY_COMMITTED: "Committed",
  PRODUCT_INVENTORY_AVAILABLE: "Available",
  PRODUCT_INVENTORY_DAMAGE: "Damage",
  PRODUCT_INVENTORY_ON_ORDER: "On Order",
  PRODUCT_INVENTORY_STATUS: "Status",
  PRODUCT_INVENTORY_ACTION: "Action",
  PRODUCT_INVENTORY_SKUS: "SKU(s)",
  PRODUCT_INVENTORY_ALL_SALE_CHANNEL: "All Sale Channel",
  PRODUCT_INVENTORY_SALE_CHANNEL: "Sale Channel(s)",
  PRODUCT_INVENTORY_STOCK_REPORT_EXCEL: "Stock Report Excel",
  PRODUCT_INVENTORY_STOCK_SUMMARY_REPORT: "Stock Summary Report",
  PRODUCT_INVENTORY_START_DATE: "Start Date",
  PRODUCT_INVENTORY_END_DATE: "End Date",
  PRODUCT_INVENTORY_SELECT_PLEASE: "Select Please",
  PRODUCT_INVENTORY_SELECT_STATION: "Select Station",
  PRODUCT_INVENTORY_QTY_AVAILABLE: "Qty Available",
};
export const inventoryArabic = {
  PRODUCT_INVENTORY_TEXT: "جرد",
  PRODUCT_INVENTORY_AVAILABLE_ONLY: "متاح فقط",
  PRODUCT_INVENTORY_SYNC_INVENTORY: "مزامنة المخزون",
  PRODUCT_INVENTORY_ACTION: "الإجراء",
  PRODUCT_INVENTORY_TITLE: "العنوان",
  PRODUCT_INVENTORY_SKU: "إس كيو يو",
  PRODUCT_INVENTORY_VARIANT: "المتغير",
  PRODUCT_INVENTORY_STORE_NAME: "اسم المتجر",
  PRODUCT_INVENTORY_PRODUCT_STATION: "محطة المنتجات",
  PRODUCT_INVENTORY_SALE_PRICE: "سعر البيع",
  PRODUCT_INVENTORY_LOW_QTY: "الكمية المنخفضة",
  PRODUCT_INVENTORY_COMMITTED: "ملتزم",
  PRODUCT_INVENTORY_AVAILABLE: "متاح",
  PRODUCT_INVENTORY_DAMAGE: "تالف",
  PRODUCT_INVENTORY_ON_ORDER: "قيد الطلب",
  PRODUCT_INVENTORY_STATUS: "الحالة",
  PRODUCT_INVENTORY_ACTION: "الإجراء",
  PRODUCT_INVENTORY_SKUS: "إس كيو يو (الأكواد)",
  PRODUCT_INVENTORY_ALL_SALE_CHANNEL: "جميع قنوات البيع",
  PRODUCT_INVENTORY_SALE_CHANNEL: "قنوات البيع",
  PRODUCT_INVENTORY_STOCK_REPORT_EXCEL: "تقرير المخزون في Excel",
  PRODUCT_INVENTORY_STOCK_SUMMARY_REPORT: "ملخص تقرير المخزون",
  PRODUCT_INVENTORY_START_DATE: "تاريخ البدء",
  PRODUCT_INVENTORY_END_DATE: "تاريخ الانتهاء",
  PRODUCT_INVENTORY_SELECT_PLEASE: "يرجى التحديد",
  PRODUCT_INVENTORY_SELECT_STATION: "اختر المحطة",
  PRODUCT_INVENTORY_QTY_AVAILABLE: "الكمية المتاحة",
};
export const inventoryChines = {
  PRODUCT_INVENTORY_TEXT: "存货",
  PRODUCT_INVENTORY_AVAILABLE_ONLY: "仅可用",
  PRODUCT_INVENTORY_SYNC_INVENTORY: "同步库存",
  PRODUCT_INVENTORY_ACTION: "操作",
  PRODUCT_INVENTORY_TITLE: "标题",
  PRODUCT_INVENTORY_SKU: "SKU（库存单位）",
  PRODUCT_INVENTORY_VARIANT: "变体",
  PRODUCT_INVENTORY_STORE_NAME: "店铺名称",
  PRODUCT_INVENTORY_PRODUCT_STATION: "产品站点",
  PRODUCT_INVENTORY_SALE_PRICE: "销售价格",
  PRODUCT_INVENTORY_LOW_QTY: "低库存数量",
  PRODUCT_INVENTORY_COMMITTED: "已承诺",
  PRODUCT_INVENTORY_AVAILABLE: "可用的",
  PRODUCT_INVENTORY_DAMAGE: "损坏",
  PRODUCT_INVENTORY_ON_ORDER: "订购中",
  PRODUCT_INVENTORY_STATUS: "状态",
  PRODUCT_INVENTORY_ACTION: "操作",
  PRODUCT_INVENTORY_SKUS: "SKU(库存单位)",
  PRODUCT_INVENTORY_ALL_SALE_CHANNEL: "所有销售渠道",
  PRODUCT_INVENTORY_SALE_CHANNEL: "销售渠道",
  PRODUCT_INVENTORY_STOCK_REPORT_EXCEL: "库存报告Excel",
  PRODUCT_INVENTORY_STOCK_SUMMARY_REPORT: "库存摘要报告",
  PRODUCT_INVENTORY_START_DATE: "开始日期",
  PRODUCT_INVENTORY_END_DATE: "结束日期",
  PRODUCT_INVENTORY_SELECT_PLEASE: "请选择",
  PRODUCT_INVENTORY_SELECT_STATION: "选择站点",
  PRODUCT_INVENTORY_QTY_AVAILABLE: "可用数量",
};
