import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
    Box,
    ButtonGroup,
    Stack
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import DataGridTabs from "../../../.reUseableComponents/DataGridTabs/DataGridTabs";
import { styleSheet } from "../../../assets/styles/style";
import CreateBankModal from "../../../components/modals/settingsModals/CreateBankModal";
import { ActionButtonCustom } from "../../../utilities/helpers/Helpers";
import PayoutBankList from "./list";

const PayoutBank = () => {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [loading, setLoading] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false);


  return (
    <>
      <Box sx={styleSheet.pageRoot}>
        <div style={{ padding: "10px" }}>
          <Box sx={styleSheet.topNavBar}>
            <Box
              sx={{ ...styleSheet.topNavBarLeft, fontWeight: "900 !important" }}
            ></Box>
            <Stack
              sx={styleSheet.topNavBarRight}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <ButtonGroup
                variant="outlined"
                aria-label="split button"
              ></ButtonGroup>
            </Stack>
          </Box>
          <DataGridTabs
            tabData={[
              {
                label: "All",
                route: "/payout-bank",
                children: (
                  <PayoutBankList
                    loading={loading}
                  />
                ),
              },
            ]}
            otherBtns={
              <ActionButtonCustom
                label={"Create Bank"}
                startIcon={<AccountBalanceIcon fontSize="small" />}
                onClick={() => setOpenBankModal(true)}
              />
            }
          />
        </div>
        {openBankModal && (
          <CreateBankModal
            open={openBankModal}
            onClose={() => setOpenBankModal(false)}
          />
        )}
      </Box>
    </>
  );
};

export default PayoutBank;
