import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styleSheet } from "../../../../assets/styles/style";
import {
  CicrlesLoading,
  greyBorder,
  navbarHeight,
  pagePadding,
  useGetWindowHeight,
} from "../../../../utilities/helpers/Helpers";
import UtilityClass from "../../../../utilities/UtilityClass";
const WebhookLogs = (props) => {
  const { logLoading, allWebhookLogs, isFilterOpen } = props;
  const { height: windowHeight } = useGetWindowHeight();
  const [responseData, setResponseData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();

  let parsedData = null;
  const handleResponse = (data, index) => {
    const parsedRequest = data?.Request ? JSON.parse(data.Request) : {};
    const parsedResponse = data?.Response ? JSON.parse(data.Response) : {};
    parsedData = {
      ...data,
      Request: parsedRequest,
      Response: parsedResponse,
    };
    setResponseData(parsedData);
    setSelectedIndex(index);
  };
  const calculatedHeight = isFilterOpen
    ? windowHeight - navbarHeight - 152.5
    : windowHeight - 95 - 36;
  return (
    <Box sx={styleSheet.pageRoot}>
      {logLoading ? (
        <CicrlesLoading />
      ) : (
        <Box
          sx={{
            padding: pagePadding,
            border: greyBorder,
            borderRadius: "8px !important",
            borderTopLeftRadius: "0px !important",
            borderTopRightRadius: "0px !important",
            height: "100%",
            minHeight: calculatedHeight,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: calculatedHeight - 20,
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                width: "calc(100% - 405px)",
                bgcolor: "background.paper",
              }}
            >
              <Typography component="h2" variant="h3">
                Webhook Logs
              </Typography>
              <List>
                {allWebhookLogs?.map((req, index) => (
                  <Box
                    key={index}
                    onClick={() => handleResponse(req, index)}
                    sx={{
                      backgroundColor:
                        selectedIndex === index
                          ? "rgba(98, 0, 234, 0.1)"
                          : "transparent",
                    }}
                  >
                    <ListItem
                      sx={{
                        paddingTop: "3px !important",
                        paddingBottom: "3px !important",
                        borderLeft:
                          selectedIndex === index
                            ? "3px solid #6200EA"
                            : "none",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Typography
                                variant="body2"
                                sx={{
                                  color:
                                    req.StatusCode === 200 ? "green" : "red",
                                  display: "inline",
                                }}
                              >
                                {req.StatusCode}
                              </Typography>{" "}
                              {"POST"} {req?.WebhookUrl}
                            </span>
                            <Typography variant="body2">
                              {UtilityClass.convertUtcToLocalAndGetDate(
                                req.CreatedOn
                              )}
                            </Typography>
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Box>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                position: "fixed",
                height: calculatedHeight - 40,
                overflowY: "auto",
                top: isFilterOpen ? 205 : 160,
                right: 20,
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  width: "380px",
                }}
              >
                <CardContent>
                  <Typography component="h2" variant="subtitle2">
                    <strong>Request Body:</strong>
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  {Object.keys(responseData).length === 0 ||
                  Object.keys(responseData.Request).length === 0 ? (
                    <pre
                      style={{
                        backgroundColor: "#f4f4f4",
                        padding: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      No Data Available
                    </pre>
                  ) : (
                    Object.entries(responseData.Request).map(([key, value]) => (
                      <Box key={key}>
                        {typeof value === "object" && value !== null ? (
                          <>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", mt: 2 }}
                            >
                              {key}
                            </Typography>
                            {Object.entries(value).map(([subKey, subValue]) => (
                              <Typography key={subKey} variant="body1">
                                <strong>
                                  {subKey.replace(/([A-Z])/g, " $1")}:{" "}
                                </strong>
                                {typeof subValue === "object"
                                  ? JSON.stringify(subValue)
                                  : subValue}
                              </Typography>
                            ))}
                          </>
                        ) : (
                          <Typography variant="body1">
                            <strong>{key.replace(/([A-Z])/g, " $1")}: </strong>
                            {value}
                          </Typography>
                        )}
                      </Box>
                    ))
                  )}

                  <Divider sx={{ my: 2 }} />
                </CardContent>
              </Card>

              <Box>
                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    width: "380px",
                  }}
                >
                  <CardContent>
                    <Typography component="h2" variant="subtitle2">
                      <strong>Response Body:</strong>
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    {Object.keys(responseData).length === 0 ||
                    Object.keys(responseData.Response).length === 0 ? (
                      <pre
                        style={{
                          backgroundColor: "#f4f4f4",
                          padding: "10px",
                          borderRadius: "4px",
                        }}
                      >
                        No Data Available
                      </pre>
                    ) : (
                      Object.entries(responseData.Response).map(
                        ([key, value]) => (
                          <Box key={key}>
                            {typeof value === "object" && value !== null ? (
                              <>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", mt: 2 }}
                                >
                                  {key}
                                </Typography>
                                {Object.entries(value).map(
                                  ([subKey, subValue]) => (
                                    <Typography key={subKey} variant="body1">
                                      <strong>
                                        {subKey.replace(/([A-Z])/g, " $1")}:{" "}
                                      </strong>
                                      {typeof subValue === "object"
                                        ? JSON.stringify(subValue)
                                        : subValue}
                                    </Typography>
                                  )
                                )}
                              </>
                            ) : (
                              <Typography variant="body1">
                                <strong>
                                  {key.replace(/([A-Z])/g, " $1")}:{" "}
                                </strong>
                                {value}
                              </Typography>
                            )}
                          </Box>
                        )
                      )
                    )}

                    <Divider sx={{ my: 2 }} />
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WebhookLogs;
