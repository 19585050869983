export const carrierEnglish = {
  INTEGRATION_CARRIER_TEXT: "Carriers",
  INTEGRATION_CARRIER_TEXT_S: "Carrier(s)",
  INTEGRATION_CARRIER_ALL_CARRIERS: "All Carriers",
  INTEGRATION_CARRIER_ACTIVE: "Active",
  INTEGRATION_CARRIER_IN_ACTIVE: "In Active",
  INTEGRATION_CLEAR_FILTER: "Clear Filter",
  INTEGRATION_CARRIER_FILTER_BY_COUNTRY: "Filter By Country",
  INTEGRATION_CARRIER_FILTER_BY_SERVICES: "Filter By Services",
  INTEGRATION_ERROR_MESSAGE: "Something went wrong",
  INTEGRATION_TEXT_COPIED_TO_CLIPBOARD: "Text copied to clipboard",
  INTEGRATION_INVALID_CONFIG_SETTING: "Invalid config setting",
  INTEGRATION_NAME: "Name",
  INTEGRATION_NICK_NAME: "Nick Name",
  INTEGRATION_WEBHOOK_URL: "WebHookUrl",
  INTEGRATION_ACTION: "Action",
  INTEGRATION_CONFIG_SETTING: "Config Setting",
  INTEGRATION_ACTIVATE: "Activate",
  INTEGRATION_DEACTIVATE: "Deactivate",
  INTEGRATION_GET_WEBHOOK_URL: "Get Webhook Url",
  INTEGRATION_EDIT_NICK_NAME: "Edit Nick Name",
  INTEGRATION_EDIT_DATA: "Edit Data",
};
export const carrierArabic = {
  INTEGRATION_CARRIER_TEXT: "الناقلون",
  INTEGRATION_CARRIER_TEXT_S: "الناقلون(s)",
  INTEGRATION_CARRIER_ALL_CARRIERS: "كل الناقلين",
  INTEGRATION_CARRIER_ACTIVE: "نشط",
  INTEGRATION_CARRIER_IN_ACTIVE: "غير نشط",
  INTEGRATION_CLEAR_FILTER: "مسح التصفية",
  INTEGRATION_CARRIER_FILTER_BY_COUNTRY: "تصفية حسب البلد",
  INTEGRATION_CARRIER_FILTER_BY_SERVICES: "تصفية حسب الخدمات",
  INTEGRATION_ERROR_MESSAGE: "حدث خطأ ما",
  INTEGRATION_TEXT_COPIED_TO_CLIPBOARD: "تم نسخ النص إلى الحافظة",
  INTEGRATION_INVALID_CONFIG_SETTING: "إعداد تكوين غير صالح",
  INTEGRATION_NAME: "الاسم",
  INTEGRATION_NICK_NAME: "الاسم المستعار",
  INTEGRATION_WEBHOOK_URL: "عنوان WebHook",
  INTEGRATION_ACTION: "إجراء",
  INTEGRATION_CONFIG_SETTING: "إعداد التكوين",
  INTEGRATION_ACTIVATE: "تفعيل",
  INTEGRATION_DEACTIVATE: "تعطيل",
  INTEGRATION_GET_WEBHOOK_URL: "الحصول على عنوان Webhook",
  INTEGRATION_EDIT_NICK_NAME: "تحرير الاسم المستعار",
  INTEGRATION_EDIT_DATA: "تحرير البيانات",
};
export const carrierChines = {
  INTEGRATION_CARRIER_TEXT: "承运人",
  INTEGRATION_CARRIER_TEXT_S: "承运人",
  INTEGRATION_CARRIER_ALL_CARRIERS: "所有承运人",
  INTEGRATION_CARRIER_ACTIVE: "活跃的",
  INTEGRATION_CARRIER_IN_ACTIVE: "活跃中",
  INTEGRATION_CLEAR_FILTER: "清除筛选",
  INTEGRATION_CARRIER_FILTER_BY_COUNTRY: "按国家过滤",
  INTEGRATION_CARRIER_FILTER_BY_SERVICES: "按服务过滤",
  INTEGRATION_ERROR_MESSAGE: "出了些问题",
  INTEGRATION_TEXT_COPIED_TO_CLIPBOARD: "文本已复制到剪贴板",
  INTEGRATION_INVALID_CONFIG_SETTING: "无效的配置设置",
  INTEGRATION_NAME: "名称",
  INTEGRATION_NICK_NAME: "昵称",
  INTEGRATION_WEBHOOK_URL: "WebHook地址",
  INTEGRATION_ACTION: "操作",
  INTEGRATION_CONFIG_SETTING: "配置设置",
  INTEGRATION_ACTIVATE: "激活",
  INTEGRATION_DEACTIVATE: "停用",
  INTEGRATION_GET_WEBHOOK_URL: "获取Webhook地址",
  INTEGRATION_EDIT_NICK_NAME: "编辑昵称",
  INTEGRATION_EDIT_DATA: "编辑数据",
};
