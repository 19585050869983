import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Chip, InputLabel, Paper } from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import DataGridComponent from "../../../.reUseableComponents/DataGrid/DataGridComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  AssignToCarrier,
  CreateDeliveryTask,
  GetCarrierWayBillsByOrderNos,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import { EnumAwbType, EnumOptions } from "../../../utilities/enum";
import {
  CodeBox,
  DataGridHeaderBox,
  fetchMethod,
  useMenuForLoop,
} from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import UtilityClass from "../../../utilities/UtilityClass";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function BatchAssigntoCarrierModal(props) {
  let {
    open,
    setOpen,
    orderNosData,
    activeCarriers,
    getAllOrders,
    resetRowRef,
    isAssignInHouse,
    setIsAssignInHouse,
  } = props;
  const [chipData, setChipData] = React.useState([]);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [filterdActiveCarriers, setFilterdActiveCarriers] = useState([]);

  const [configErrors, setConfigErrors] = useState({
    rows: [],
    data: {},
  });
  const [errors, setErrors] = useState({
    rows: [],
    data: {},
  });
  useEffect(() => {
    //without default filter
    if (activeCarriers && activeCarriers.length > 0) {
      const filterData = activeCarriers.filter((x) => x.CarrierId < 1000);
      setFilterdActiveCarriers(filterData);
    }
  }, [activeCarriers]);

  useEffect(() => {
    const cData = UtilityClass.getChipDataFromTrackingArr(orderNosData);
    setChipData(cData);
  }, [orderNosData]);

  const handleClose = () => {
    setOpen(false);
    setIsAssignInHouse(false);
  };
  const [carrierId, setCarrierId] = useState({
    ActiveCarrierId: 0,
    Name: "Select Please",
  });
  const handleAssigntoCarrier = () => {
    if (carrierId?.CarrierId && carrierId?.CarrierId == 0) {
      errorNotification("Please choose carrier");
      return false;
    }

    const _orderNos = chipData.map((item) => item.label).join();
    let param = {
      carrierId: carrierId?.CarrierId,
      ActiveCarrierId: carrierId?.ActiveCarrierId,
      orderNos: _orderNos,
    };
    if (!carrierId.ActiveCarrierId || carrierId.ActiveCarrierId == 0) {
      errorNotification("Please select Carrier");
      return false;
    }
    setIsLoading(true);

    AssignToCarrier(param)
      .then(async (res) => {
        setIsLoading(false);
        if (!res.data.isSuccess) {
          if (!res.data.isSuccess && res.data.errors) {
            const _erros = res.data.errors;
            const _rows = Object.keys(_erros).map((data) => {
              return { orderNo: data };
            });
            setErrors((prev) => ({ ...prev, data: _erros, rows: _rows }));
          }
          if (!res.data.isSuccess && res.data.configErrors) {
            const _configError = res.data.configErrors;
            const _configRow = Object.keys(_configError).map((data) => {
              return { orderNo: data };
            });
            setConfigErrors((prev) => ({
              ...prev,
              data: _configError,
              rows: _configRow,
            }));
          }
        } else {
          const { response } = await fetchMethod(() =>
            GetCarrierWayBillsByOrderNos({
              orderNos: _orderNos,
              awbTypeId: EnumAwbType.CarrierAwbTypeId,
            })
          );
          if (response) {
            UtilityClass.downloadPdf(response, "Awb");
          }
          successNotification("Order assign to carrier successfully");
          resetRowRef.current = true;
          getAllOrders();
          setOpen(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        errorNotification(
          LanguageReducer?.languageType
            ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
        );
      });
  };
  const handleAssignInHouse = () => {
    let param = {
      orderNos: chipData.map((item) => item.label).join(),
    };
    setIsLoading(true);
    CreateDeliveryTask(param)
      .then((res) => {
        setIsLoading(false);
        if (!res.data.isSuccess) {
          let jsonData = res.data.errors;
          for (const key in jsonData) {
            if (jsonData.hasOwnProperty(key)) {
              const messagesArray = jsonData[key];
              // Loop through the messages array for each key
              for (const message of messagesArray) {
                //   console.log(`${key}: ${message}`);
                errorNotification(message);
              }
            }
          }
        } else {
          successNotification("Order assign to carrier successfully");
          resetRowRef.current = true;
          getAllOrders();
          setOpen(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        errorNotification(
          LanguageReducer?.languageType
            ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
        );
        console.log("e", e);
      });
  };
  const handleSubmitClick = () => {
    if (isAssignInHouse) {
      handleClose();
      handleAssignInHouse();
    } else {
      handleAssigntoCarrier();
    }
  };
  const {
    anchorEl,
    openElem,
    handleOpen,
    handleClose: handleCloseErrorsMenu,
  } = useMenuForLoop();

  const configColumns = [
    {
      field: "OrderNo",
      headerName: <DataGridHeaderBox title={"Title"} />,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <CodeBox title={params.row.orderNo} />
          </>
        );
      },
    },
    {
      field: "Errors",
      headerName: <DataGridHeaderBox title={"Errors"} />,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        const errorsForOrder = configErrors.data[row.orderNo] || [];
        return (
          <>
            {errorsForOrder.map((dt, index) => (
              <Box
                key={index}
                style={{
                  padding: "4px 0",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {index + 1}. {dt}
              </Box>
            ))}
          </>
        );
      },
    },
  ];
  const columns = [
    {
      field: "OrderNo",
      headerName: <DataGridHeaderBox title={"Title"} />,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <CodeBox title={params.row.orderNo} />
          </>
        );
      },
    },
    {
      field: "Errors",
      headerName: <DataGridHeaderBox title={"Errors"} />,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        const errorsForOrder = errors.data[row.orderNo] || [];
        return (
          <>
            {errorsForOrder.map((dt, index) => (
              <Box
                key={index}
                style={{
                  padding: "4px 0",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {index + 1}. {dt}
              </Box>
            ))}
          </>
        );
      },
    },
  ];
  return (
    <>
      <ModalComponent
        maxWidth="md"
        open={open}
        onClose={handleClose}
        title={
          !isAssignInHouse
            ? LanguageReducer?.languageType?.ORDER_BATCH_ASSIGN_TO_CARRIER
            : LanguageReducer?.languageType?.ORDER_ASSIGN_TO_CARRIER
        }
        actionBtn={
          <ModalButtonComponent
            loading={isLoading}
            onClick={handleSubmitClick}
            title={
              isAssignInHouse
                ? LanguageReducer?.languageType?.ORDER_ASSIGN_IN_HOUSE
                : LanguageReducer?.languageType?.ORDER_ASSIGN_TO_CARRIER
            }
          />
        }
      >
        {configErrors.rows.length > 0 && (
          <Box>
            <DataGridComponent
              autoHeight
              bgColor={"#fff"}
              getRowHeight={() => "auto"}
              headerHeight={40}
              sx={{
                fontFamily:
                  "'Lato Regular', 'Inter Regular', 'Arial' !important",
                fontSize: "12px",
                fontWeight: "500",
              }}
              rows={configErrors.rows}
              getRowId={(row) => row.orderNo}
              columns={configColumns}
              checkboxSelection={false}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[5, 10, 15, 25]}
            />
          </Box>
        )}
        <Card variant="outlined" sx={styleSheet.tagsCard}>
          {/* <Typography sx={styleSheet.tagsCardHeading}>{"OrderNos"}</Typography> */}
          <Paper
            sx={{
              display: "flex  !important",
              justifyContent: "flex-start  !important",
              flexWrap: "wrap  !important",
              p: 0.5,
              m: 0,
            }}
            elevation={0}
          >
            {chipData?.map((data) => {
              return (
                <Box key={data.key} sx={{ mr: "10px", mb: "8px" }}>
                  <Chip
                    sx={styleSheet.tagsChipStyle}
                    size="small"
                    icon={
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{ color: "white  !important" }}
                      />
                    }
                    deleteIcon={
                      <CloseIcon sx={{ color: "white  !important" }} />
                    }
                    label={data.label}
                    // onDelete={() => { }}
                  />
                </Box>
              );
            })}
          </Paper>
        </Card>
        <br />
        {!isAssignInHouse && (
          <>
            <InputLabel sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.ORDER_SELECT_CARRIER}
            </InputLabel>
            <SelectComponent
              name="carrier"
              options={filterdActiveCarriers}
              value={carrierId}
              height={40}
              getOptionLabel={(option) => option.Name}
              optionLabel={EnumOptions.CARRIER.LABEL}
              optionValue={EnumOptions.CARRIER.VALUE}
              onChange={(e, newValue) => {
                const resolvedId = newValue ? newValue : null;
                setCarrierId(resolvedId);
              }}
              size={"md"}
            />
          </>
        )}
        <br />
        {errors.rows.length > 0 && (
          <Box>
            <DataGridComponent
              autoHeight
              bgColor={"#fff"}
              getRowHeight={() => "auto"}
              headerHeight={40}
              sx={{
                fontFamily:
                  "'Lato Regular', 'Inter Regular', 'Arial' !important",
                fontSize: "12px",
                fontWeight: "500",
              }}
              rows={errors.rows}
              getRowId={(row) => row.orderNo}
              columns={columns}
              checkboxSelection={false}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[5, 10, 15, 25]}
            />
          </Box>
        )}
      </ModalComponent>
    </>
  );
}
export default BatchAssigntoCarrierModal;
