export const codPendingEnglish = {
  ACCOUNTS_COD_PENDING_TEXT: "COD Pending",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT: "Create Settlement",
  ACCOUNTS_COD_PENDING_START_DATE: "Start Date",
  ACCOUNTS_COD_PENDING_END_DATE: "End Date",
  ACCOUNTS_COD_PENDING_STORE: "Store",
  ACCOUNTS_COD_PENDING_IMPORT: "Import",
  ACCOUNTS_COD_PENDING_DOWNLOAD_SAMPLE: "Download Sample",
  ACCOUNTS_COD_PENDING_CARRIER: "Carrier",
  ACCOUNTS_COD_PENDING_ORDER_NO: "Order No.",
  ACCOUNTS_COD_PENDING_CARRIER_NAME: "Carrier Name",
  ACCOUNTS_COD_PENDING_STORE_REF: "Store Ref.",
  ACCOUNTS_COD_PENDING_TRACKING_STATUS: "Tracking Status",
  ACCOUNTS_COD_PENDING_PAYMENT_STATUS: "Payment Status",
  ACCOUNTS_COD_PENDING_ORDER_TYPE: "Order Type",
  ACCOUNTS_COD_PENDING_PAYMENT: "Payment",
  ACCOUNTS_COD_PENDING_PAYMENT_DATE: "Payment Date",
  ACCOUNTS_COD_PENDING_ORDER_DATE: "Order Date",
  ACCOUNTS_COD_PENDING_AMOUNT: "Amount",
  ACCOUNTS_COD_PENDING_ACTION: "Action",
  ACCOUNTS_COD_PENDING_UPDATE_AMOUNT: "Update Amount",
  ACCOUNTS_COD_PENDING_TRACKING_NO: "Tracking no",
  ACCOUNTS_COD_PENDING_PAYMENT_REFERENCE: "Payment Reference",
  ACCOUNTS_COD_PENDING_FILE_COD: "File COD",
  ACCOUNTS_COD_PENDING_DB_COD: "DB COD",
  ACCOUNTS_COD_PENDING_DIFFERENCE: "Difference",
  ACCOUNTS_COD_PENDING_DISMISS: "Dismiss",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT_FROM_FILE:
    "Create Settlement From File",
  ACCOUNTS_COD_PENDING_VALUE_SET_WHEN_CREATING_ORDER:
    "Value set when creating order",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT_FROM_FILE:
    "Create Settlement From File",
  ACCOUNTS_COD_PENDING_PLEASE_SELECT_THE_CARRIER_FROM_FILTER:
    "Please select the carrier from filter",
};
export const codPendingArabic = {
  ACCOUNTS_COD_PENDING_TEXT: "الدفع عند الاستلام معلق",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT: "إنشاء تسوية",
  ACCOUNTS_COD_PENDING_START_DATE: "تاريخ البدء",
  ACCOUNTS_COD_PENDING_END_DATE: "تاريخ الانتهاء",
  ACCOUNTS_COD_PENDING_STORE: "المتجر",
  ACCOUNTS_COD_PENDING_IMPORT: "استيراد",
  ACCOUNTS_COD_PENDING_DOWNLOAD_SAMPLE: "تنزيل النموذج",
  ACCOUNTS_COD_PENDING_ORDER_TYPE: "نوع الطلب",
  ACCOUNTS_COD_PENDING_CARRIER: "الناقل",
  ACCOUNTS_COD_PENDING_ORDER_NO: "رقم الطلب",
  ACCOUNTS_COD_PENDING_CARRIER_NAME: "اسم الناقل",
  ACCOUNTS_COD_PENDING_STORE_REF: "مرجع المتجر",
  ACCOUNTS_COD_PENDING_TRACKING_STATUS: "حالة التتبع",
  ACCOUNTS_COD_PENDING_PAYMENT_STATUS: "حالة الدفع",
  ACCOUNTS_COD_PENDING_PAYMENT: "الدفع",
  ACCOUNTS_COD_PENDING_ORDER_DATE: "تاريخ الطلب",
  ACCOUNTS_COD_PENDING_PAYMENT_DATE: "تاريخ الدفع",
  ACCOUNTS_COD_PENDING_AMOUNT: "المبلغ",
  ACCOUNTS_COD_PENDING_UPDATE_AMOUNT: "تحديث المبلغ",
  ACCOUNTS_COD_PENDING_TRACKING_NO: "رقم التتبع",
  ACCOUNTS_COD_PENDING_PAYMENT_REFERENCE: "مرجع الدفع",
  ACCOUNTS_COD_PENDING_PAYMENT_STATUS: "حالة الدفع",
  ACCOUNTS_COD_PENDING_FILE_COD: "ملف الدفع عند الاستلام",
  ACCOUNTS_COD_PENDING_DB_COD: "قاعدة بيانات الدفع عند الاستلام",
  ACCOUNTS_COD_PENDING_DIFFERENCE: "الفرق",
  ACCOUNTS_COD_PENDING_ACTION: "الإجراء",
  ACCOUNTS_COD_PENDING_DISMISS: "رفض",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT_FROM_FILE: "إنشاء تسوية من الملف",
  ACCOUNTS_COD_PENDING_VALUE_SET_WHEN_CREATING_ORDER:
    "القيمة المحددة عند إنشاء الطلب",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT_FROM_FILE: "إنشاء تسوية من الملف",
  ACCOUNTS_COD_PENDING_PLEASE_SELECT_THE_CARRIER_FROM_FILTER:
    "يرجى اختيار الناقل من الفلتر",
};
export const codPendingChines = {
  ACCOUNTS_COD_PENDING_TEXT: "货到付款待处理",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT: "创建结算",
  ACCOUNTS_COD_PENDING_START_DATE: "开始日期",
  ACCOUNTS_COD_PENDING_END_DATE: "结束日期",
  ACCOUNTS_COD_PENDING_PAYMENT_DATE: "付款日期",
  ACCOUNTS_COD_PENDING_STORE: "店铺",
  ACCOUNTS_COD_PENDING_ORDER_TYPE: "订单类型",
  ACCOUNTS_COD_PENDING_IMPORT: "导入",
  ACCOUNTS_COD_PENDING_DOWNLOAD_SAMPLE: "下载示例",
  ACCOUNTS_COD_PENDING_CARRIER: "承运人",
  ACCOUNTS_COD_PENDING_ORDER_NO: "订单编号",
  ACCOUNTS_COD_PENDING_CARRIER_NAME: "承运人名称",
  ACCOUNTS_COD_PENDING_STORE_REF: "店铺参考",
  ACCOUNTS_COD_PENDING_TRACKING_STATUS: "跟踪状态",
  ACCOUNTS_COD_PENDING_PAYMENT_STATUS: "付款状态",
  ACCOUNTS_COD_PENDING_PAYMENT: "付款",
  ACCOUNTS_COD_PENDING_ORDER_DATE: "订单日期",
  ACCOUNTS_COD_PENDING_AMOUNT: "金额",
  ACCOUNTS_COD_PENDING_ACTION: "操作",
  ACCOUNTS_COD_PENDING_UPDATE_AMOUNT: "更新金额",
  ACCOUNTS_COD_PENDING_TRACKING_NO: "跟踪号",
  ACCOUNTS_COD_PENDING_PAYMENT_REFERENCE: "付款参考",
  ACCOUNTS_COD_PENDING_PAYMENT_STATUS: "付款状态",
  ACCOUNTS_COD_PENDING_FILE_COD: "文件货到付款",
  ACCOUNTS_COD_PENDING_DB_COD: "数据库货到付款",
  ACCOUNTS_COD_PENDING_DIFFERENCE: "差异",
  ACCOUNTS_COD_PENDING_DISMISS: "取消",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT_FROM_FILE: "从文件创建结算",
  ACCOUNTS_COD_PENDING_VALUE_SET_WHEN_CREATING_ORDER: "创建订单时设置的值",
  ACCOUNTS_COD_PENDING_CREATE_SETTLEMENT_FROM_FILE: "从文件创建结算",
  ACCOUNTS_COD_PENDING_PLEASE_SELECT_THE_CARRIER_FROM_FILTER:
    "请从过滤器中选择承运人",
};
