import { Box } from "@mui/material";
import React, { useState } from "react";
import DataGridProComponent from "../../../../.reUseableComponents/DataGrid/DataGridProComponent";
import { styleSheet } from "../../../../assets/styles/style";
import StatusBadge from "../../../../components/shared/statudBadge";
import { EnumChangeFilterModelApiUrls } from "../../../../utilities/enum";
import {
  ActionButtonEdit,
  centerColumn,
  DialerBox,
  useGetWindowHeight,
} from "../../../../utilities/helpers/Helpers";
import ReturnOrderModal from "../../../../components/modals/orderModals/ReturnOrderModal";
import { useSelector } from "react-redux";

const ReturnOrderList = (props) => {
  const { isLoading, allOrderReturnData, isFilterOpen, getAllOrderReturn } =
    props;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const { height: windowHeight } = useGetWindowHeight();
  const [openReturnOrderModal, setOpenReturnOrderModal] = useState({
    open: false,
  });
  const [returnID, setReturnID] = useState();
  const column = [
    {
      field: "Name",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_CUSTOMER_NAME}
        </Box>
      ),
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box disableRipple>
            <>
              <Box>{row?.CustomerName}</Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "Store",
      // headerAlign: "center",
      // align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_STORE_INFO}
        </Box>
      ),
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box disableRipple>
            <>
              <Box>{row.StoreName}</Box>
              <Box>
                <DialerBox phone={row.CustomerServiceNo} />
              </Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "Payment",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_PAYMENT_STATUS}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              <Box sx={{ fontWeight: "bold" }}>{row.PaymentMethod}</Box>
              <StatusBadge
                title={row.PaymentStatus}
                color={row.PaymentStatus === "Unpaid" ? "#fff;" : "#fff;"}
                bgColor={
                  row.PaymentStatus === "Unpaid" ? "#dc3545;" : "#28a745;"
                }
              />
            </>
          </Box>
        );
      },
    },
    {
      field: "reason",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDER_RETURN_REASON}
        </Box>
      ),
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box disableRipple>
            <>
              <Box sx={{ fontSize: "10px" }}>{row?.Reason}</Box>
            </>
          </Box>
        );
      },
    },
    {
      ...centerColumn,
      field: "Action",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ORDERS_ACTION}
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <Box display={"flex"} gap={1}>
            <ActionButtonEdit
              onClick={() => {
                setReturnID(row.ReturnId);
                setOpenReturnOrderModal((prev) => ({ ...prev, open: true }));
              }}
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const calculatedHeight = isFilterOpen
    ? windowHeight - 95 - 59 - 62.5 - 40
    : windowHeight - 95 - 59 - 16.5;
  return (
    <>
      <Box
        sx={{
          ...styleSheet.allOrderTable,
          height: calculatedHeight,
          paddingBottom: "20px",
        }}
      >
        <DataGridProComponent
          rowPadding={8}
          rows={allOrderReturnData}
          columns={column}
          loading={isLoading}
          headerHeight={40}
          getRowId={(row) => row.RowNum}
          checkboxSelection={false}
          disableSelectionOnClick
          rowsCount={allOrderReturnData.length}
          paginationChangeMethod={allOrderReturnData}
          paginationMethodUrl={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.url}
          defaultRowsPerPage={
            EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length
          }
          height={calculatedHeight}
        />
      </Box>
      {openReturnOrderModal && (
        <ReturnOrderModal
          returnID={returnID}
          getAllOrderReturn={getAllOrderReturn}
          open={openReturnOrderModal.open}
          onClose={() =>
            setOpenReturnOrderModal((prev) => ({ ...prev, open: false }))
          }
        />
      )}
    </>
  );
};
export default ReturnOrderList;
