import React from "react";
import { EnumRoutesUrls } from "../../../utilities/enum";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { greyBorder } from "../../../utilities/helpers/Helpers";
import { useNavigate } from "react-router-dom";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
export const getSubTabData = (LanguageReducer) => {
  return [
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_DRIVER_STATUSES_TEXT,
      topTitle: LanguageReducer?.languageType?.SETTING_DRIVER_STATUSES_TEXT_S,
      icon: <SettingsApplicationsOutlinedIcon style={{ fontSize: 40 }} />,
      path: "/driver-statuses",
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_RETURN_REASON_TEXT,
      topTitle: LanguageReducer?.languageType?.SETTING_RETURN_REASON_TEXT,
      icon: <KeyboardReturnIcon style={{ fontSize: 40 }} />,
      path: "/return-reason",
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_SHIPMENT_TAB_TEXT,
      topTitle: LanguageReducer?.languageType?.SETTING_SHIPMENT_TAB_TEXT,
      icon: <SettingsApplicationsOutlinedIcon style={{ fontSize: 40 }} />,
      path: "/shipment-tab",
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_PERMISSION_TEXT,
      topTitle: LanguageReducer?.languageType?.SETTING_PERMISSION_TEXT,
      icon: <SettingsApplicationsOutlinedIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.PERRMISSIONS,
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_META_FIELD_TEXT,
      topTitle: LanguageReducer?.languageType?.SETTING_META_FIELD_TEXT,
      icon: <TextFieldsIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.META_FIELDS,
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_CHOOSE_ROLES_TEXT,
      topTitle: LanguageReducer?.languageType?.SETTING_CHOOSE_ROLES_TEXT,
      icon: <PersonOutlinedIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.USER_ROLES,
    },
    {
      sideTitle: LanguageReducer?.languageType?.NOTIFICATION,
      topTitle: LanguageReducer?.languageType?.NOTIFICATION,
      icon: <NotificationsNoneOutlinedIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.Notification,
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_ORDER_BOX,
      topTitle: LanguageReducer?.languageType?.SETTING_ORDER_BOX,
      icon: <Inventory2OutlinedIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.ORDER_BOX,
    },
    {
      sideTitle: LanguageReducer?.languageType?.SETTING_WEBHOOK_EVENT,
      topTitle: LanguageReducer?.languageType?.SETTING_WEBHOOK_EVENT,
      icon: <SettingsApplicationsOutlinedIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.WEBHOOK_EVENTS,
    },
    {
      sideTitle: LanguageReducer?.languageType?.PAYOUT_BANK,
      topTitle: LanguageReducer?.languageType?.PAYOUT_BANK,
      icon: <AccountBalanceOutlinedIcon style={{ fontSize: 40 }} />,
      path: EnumRoutesUrls.PAYOUT_BANK,
    },
  ];
};
const SettingPage = () => {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const navigate = useNavigate();

  return (
    <Box p={1.5}>
      <Grid container spacing={2}>
        {getSubTabData(LanguageReducer).map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                bgcolor: "#f5f5f5",
                border: greyBorder,
                boxShadow: "none",
                borderRadius: 2,
                cursor: "pointer",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              onClick={() => navigate(data.path)}
            >
              <CardContent sx={{ padding: "16px !important" }}>
                <Box display="flex" alignItems="center">
                  {data.icon}
                  <Box ml={2}>
                    <Typography variant="body2" color="textSecondary">
                      {data.sideTitle}
                    </Typography>
                  </Box>
                </Box>
                {data.subText && (
                  <Typography variant="body2" color="textSecondary">
                    {data.subText}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SettingPage;
