export const returnOrderEnglish = {
  ORDER_RETURN_ORDER: "Return Order",
  ORDER_RETURN_REASON: "Return Reason",
  ORDER_RETURN_ORDER_NEW: "NEW",
  ORDER_RETURN_ORDER_APPROVED: "Approved",
  ORDER_RETURN_ORDER_RECEIVED: "Received",
  ORDER_RETURN_ORDER_REJECTED: "Rejected",
  ORDER_RETURN_ORDER_REFUND: "ReFund",
};
export const returnOrderArabic = {
  ORDER_RETURN_ORDER: "إرجاع الطلب",
  ORDER_RETURN_REASON: "سبب الإرجاع",
  ORDER_RETURN_ORDER: "طلب الإرجاع",
  ORDER_RETURN_ORDER_NEW: "جديد",
  ORDER_RETURN_ORDER_APPROVED: "موافق عليه",
  ORDER_RETURN_ORDER_RECEIVED: "تم الاستلام",
  ORDER_RETURN_ORDER_REJECTED: "مرفوض",
  ORDER_RETURN_ORDER_REFUND: "استرداد",
};
export const retuenOrderChines = {
  ORDER_RETURN_ORDER: "退货订单",
  ORDER_RETURN_REASON: "创建原因",
  ORDER_RETURN_ORDER: "退货订单",
  ORDER_RETURN_ORDER_NEW: "新订单",
  ORDER_RETURN_ORDER_APPROVED: "已批准",
  ORDER_RETURN_ORDER_RECEIVED: "已收到",
  ORDER_RETURN_ORDER_REJECTED: "已拒绝",
  ORDER_RETURN_ORDER_REFUND: "退款",
};
