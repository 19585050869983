import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGridTabs from "../../../.reUseableComponents/DataGridTabs/DataGridTabs";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
import { useSelector } from "react-redux";
import PriceCalculatorList from "./list";
import { styleSheet } from "../../../assets/styles/style";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import UtilityClass from "../../../utilities/UtilityClass";
import { GetAllCarrierWithServiceAndLocation } from "../../../api/AxiosInterceptors";

const PriceCalculator = () => {
  const { startDate, endDate, setStartDate, setEndDate, resetDates } =
    useDateRangeHook();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isfilterClear, setIsfilterClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carrierData, setCarrierData] = useState([]);
  const [carriersCount, setCarriersCount] = useState();
  const handleFilterClear = async () => {
    setIsfilterClear(true);
    resetDates();
  };
  const handleFilter = () => {};

  const getAllCarriersData = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllCarrierWithServiceAndLocation({
        filterModel: {
          start: 0,
          length: 100,
          search: "",
          sortDir: "desc",
          sortCol: 0,
        },
        countryId: 0,
        deliveryServiceId: 0,
      });
      setCarrierData(
        response?.data?.result?.list?.map((dt, index) => ({ ...dt, index }))
      );
      setCarriersCount(response?.data?.result?.TotalCount);
    } catch (error) {
      console.error("Error in updating getting these carriers", error.response);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllCarriersData();
  }, []);
  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        <DataGridTabs
          filterData={
            isFilterOpen ? (
              <Table
                sx={{ ...styleSheet.generalFilterArea }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <Grid container spacing={2} sx={{ p: "15px" }}>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{
                              ...styleSheet.inputLabel,
                              overflow: "unset",
                            }}
                          >
                            {"Start Date"}
                          </InputLabel>
                          <CustomReactDatePickerInputFilter
                            value={startDate}
                            onClick={(date) => setStartDate(date)}
                            size="small"
                            isClearable
                            maxDate={UtilityClass.todayDate()}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{
                              ...styleSheet.inputLabel,
                              overflow: "unset",
                            }}
                          >
                            {"End Date"}
                          </InputLabel>
                          <CustomReactDatePickerInputFilter
                            value={endDate}
                            onClick={(date) => setEndDate(date)}
                            size="small"
                            minDate={startDate}
                            disabled={!startDate ? true : false}
                            isClearable
                            maxDate={UtilityClass.todayDate()}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Stack
                          alignItems="flex-end"
                          direction="row"
                          spacing={1}
                          sx={{
                            ...styleSheet.filterButtonMargin,
                            height: "100%",
                          }}
                        >
                          <Button
                            sx={{
                              ...styleSheet.filterIcon,
                              minWidth: "100px",
                            }}
                            color="inherit"
                            variant="outlined"
                            onClick={() => {
                              handleFilterClear();
                            }}
                          >
                            {LanguageReducer?.languageType?.ORDER_CLEAR_FILTER}
                          </Button>
                          <Button
                            sx={{
                              ...styleSheet.filterIcon,
                              minWidth: "100px",
                            }}
                            variant="contained"
                            onClick={handleFilter}
                          >
                            {LanguageReducer?.languageType?.ORDERS_FILTER}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TableRow>
                </TableHead>
              </Table>
            ) : null
          }
          tabData={[
            {
              label: "All",
              route: "/price-calculator",
              children: (
                <PriceCalculatorList
                  carrierData={carrierData}
                  isLoading={isLoading}
                  isFilterOpen={isFilterOpen}
                  carriersCount={carriersCount}
                />
              ),
            },
          ]}
          handleFilterBtnOnClick={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
        />
      </div>
    </Box>
  );
};

export default PriceCalculator;
