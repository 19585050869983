export const returnReasonEnglish = {
  SETTING_RETURN_REASON_TEXT: "Return Reason",
  SETTING_RETURN_REASON_CREATE_REASON: "Create Reason",
  SETTING_RETURN_REASON_REASON: "Reason",
  SETTING_RETURN_REASON_CREATED_BY: "Created By",
  SETTING_RETURN_REASON_REASON_DETAIL: "Reason Detail",
  SETTING_RETURN_REASON_STATUS: "Status",
  SETTING_RETURN_REASON_ACTION: "Action",
  SETTING_RETURN_REASON_ACTIVE: "Active",
  SETTING_RETURN_REASON_IN_ACTIVE: "Inactive",
  SETTING_RETURN_REASON_CREATE_RETURN_REASON: "Create Return Reason",
  SETTING_RETURN_REASON_CREATE_REASON: "Create Reason",
  SETTING_RETURN_REASON_REASON: "Reason",
  SETTING_RETURN_REASON_DETAIL_REASON: "Detail Reason",
  SETTING_RETURN_REASON_UPDATE_RETURN_REASON: "Update Return Reason",
  SETTING_RETURN_REASON_UPDATE_REASON: "Update Reason",
  SETTING_RETURN_REASON_ARE_YOU_SURE_TO_DISABLE_THIS_ITEM:
    "Are you sure to disable this item?",
  SETTING_RETURN_REASON_AFTER_THIS_ACTION_THE_ITEM_WILL_BE_DISABLED_IMMEDIATELY_BUT_YOU_CAN_UNDO_THIS_ACTION_ANYTIME:
    "After this action the item will be disabled immediately but you can undo this action anytime.",
  SETTING_RETURN_REASON_DISABLE: "Disable",
};
export const returnReasonArabic = {
  SETTING_RETURN_REASON_TEXT: "سبب الإرجاع",
  SETTING_RETURN_REASON_CREATE_REASON: "إنشاء سبب",
  SETTING_RETURN_REASON_REASON: "سبب",
  SETTING_RETURN_REASON_CREATED_BY: "أنشأ بواسطة",
  SETTING_RETURN_REASON_REASON_DETAIL: "تفاصيل السبب",
  SETTING_RETURN_REASON_STATUS: "الحالة",
  SETTING_RETURN_REASON_ACTION: "إجراء",
  SETTING_RETURN_REASON_ACTIVE: "نشط",
  SETTING_RETURN_REASON_IN_ACTIVE: "غير نشط",
  SETTING_RETURN_REASON_CREATE_RETURN_REASON: "إنشاء سبب الإرجاع",
  SETTING_RETURN_REASON_CREATE_REASON: "إنشاء سبب",
  SETTING_RETURN_REASON_REASON: "سبب",
  SETTING_RETURN_REASON_DETAIL_REASON: "سبب التفاصيل",
  SETTING_RETURN_REASON_UPDATE_RETURN_REASON: "تحديث سبب الإرجاع",
  SETTING_RETURN_REASON_UPDATE_REASON: "تحديث السبب",
  SETTING_RETURN_REASON_ARE_YOU_SURE_TO_DISABLE_THIS_ITEM:
    "هل أنت متأكد أنك تريد تعطيل هذا العنصر؟",
  SETTING_RETURN_REASON_AFTER_THIS_ACTION_THE_ITEM_WILL_BE_DISABLED_IMMEDIATELY_BUT_YOU_CAN_UNDO_THIS_ACTION_ANYTIME:
    "بعد هذه العملية، سيتم تعطيل العنصر على الفور، ولكن يمكنك التراجع عن هذه العملية في أي وقت.",
  SETTING_RETURN_REASON_DISABLE: "تعطيل",
};
export const returnReasonChines = {
  SETTING_RETURN_REASON_TEXT: "退货原因",
  SETTING_RETURN_REASON_CREATE_REASON: "创建原因",
  SETTING_RETURN_REASON_REASON: "原因",
  SETTING_RETURN_REASON_CREATED_BY: "创建者",
  SETTING_RETURN_REASON_REASON_DETAIL: "原因详细信息",
  SETTING_RETURN_REASON_STATUS: "状态",
  SETTING_RETURN_REASON_ACTION: "操作",
  SETTING_RETURN_REASON_ACTIVE: "活跃",
  SETTING_RETURN_REASON_IN_ACTIVE: "非活跃",
  SETTING_RETURN_REASON_CREATE_RETURN_REASON: "创建退货原因",
  SETTING_RETURN_REASON_CREATE_REASON: "创建原因",
  SETTING_RETURN_REASON_REASON: "原因",
  SETTING_RETURN_REASON_DETAIL_REASON: "详细原因",
  SETTING_RETURN_REASON_UPDATE_RETURN_REASON: "更新退货原因",
  SETTING_RETURN_REASON_UPDATE_REASON: "更新原因",
  SETTING_RETURN_REASON_ARE_YOU_SURE_TO_DISABLE_THIS_ITEM:
    "您确定要禁用此项吗？",
  SETTING_RETURN_REASON_AFTER_THIS_ACTION_THE_ITEM_WILL_BE_DISABLED_IMMEDIATELY_BUT_YOU_CAN_UNDO_THIS_ACTION_ANYTIME:
    "在此操作后，项将立即禁用，但您可以随时撤销此操作。",
  SETTING_RETURN_REASON_DISABLE: "禁用",
};
