export const webhookEventEnglish = {
  SETTING_WEBHOOK_EVENT: "Webhook Events",
  SETTING_WEBHOOK: "Webhook",
  SETTING_WEBHOOK_LOGS: "Logs",
  SETTING_WEBHOOK_CREATE_WEBHOOK: "Create Webhook",
  SETTING_WEBHOOK_WEBHOOK_EVENT: "Webhook Event",
  SETTING_WEBHOOK_URL: "url",
  SETTING_WEBHOOK_WEBHOOK_URL: "Webhook Url",
  SETTING_WEBHOOK_EVENT_NAME: "Event Name",
  SETTING_WEBHOOK_CREATED_ON: "CreatedOn",
  SETTING_WEBHOOK_ACTION: "Action",
  SETTING_WEBHOOK_ADD_END_POINT: "Add end point",
  SETTING_WEBHOOK_UPDATE_WEBHOOK: "Update Webhook",
  SETTING_WEBHOOK_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM:
    "Are you sure you want to delete this item?",
  SETTING_WEBHOOK_AFTER_THIS_ACTION_THE_ITEM_WILL_BE_DELETED_IMMEDIATELY_AND_YOU_CANT_UNDO_THIS_ACTION:
    "After this action the item will be Delete immediately and you can't undo this action.",
  SETTING_WEBHOOK_DELETE: "Delete",
};
export const webhookEventArabic = {
  SETTING_WEBHOOK_EVENT: "أحداث الويب هوك",
  SETTING_WEBHOOK: "ويب هوك",
  SETTING_WEBHOOK_LOGS: "السجلات",
  SETTING_WEBHOOK_CREATE_WEBHOOK: "إنشاء ويب هوك",
  SETTING_WEBHOOK_WEBHOOK_EVENT: "حدث الويب هوك",
  SETTING_WEBHOOK_URL: "الرابط",
  SETTING_WEBHOOK_WEBHOOK_URL: "رابط الويب هوك",
  SETTING_WEBHOOK_ADD_END_POINT: "إضافة نقطة نهاية",
  SETTING_WEBHOOK_EVENT_NAME: "اسم الحدث",
  SETTING_WEBHOOK_CREATED_ON: "تاريخ الإنشاء",
  SETTING_WEBHOOK_ACTION: "إجراء",
  SETTING_WEBHOOK_UPDATE_WEBHOOK: "تحديث ويب هوك",
  SETTING_WEBHOOK_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM:
    "هل أنت متأكد أنك تريد حذف هذه العنصر؟",
  SETTING_WEBHOOK_AFTER_THIS_ACTION_THE_ITEM_WILL_BE_DELETED_IMMEDIATELY_AND_YOU_CANT_UNDO_THIS_ACTION:
    "بعد هذه الخطوة، سيتم حذف العنصر على الفور ولا يمكنك التراجع عن هذا الإجراء.",
  SETTING_WEBHOOK_DELETE: "حذف",
};
export const webhookEventChines = {
  SETTING_WEBHOOK_EVENT: "网络钩子事件",
  SETTING_WEBHOOK: "Webhook",
  SETTING_WEBHOOK_LOGS: "日志",
  SETTING_WEBHOOK_CREATE_WEBHOOK: "创建Webhook",
  SETTING_WEBHOOK_WEBHOOK_EVENT: "Webhook事件",
  SETTING_WEBHOOK_URL: "网址",
  SETTING_WEBHOOK_ADD_END_POINT: "添加端点",
  SETTING_WEBHOOK_WEBHOOK_URL: "Webhook URL",
  SETTING_WEBHOOK_EVENT_NAME: "事件名称",
  SETTING_WEBHOOK_CREATED_ON: "创建于",
  SETTING_WEBHOOK_ACTION: "操作",
  SETTING_WEBHOOK_UPDATE_WEBHOOK: "更新Webhook",
  SETTING_WEBHOOK_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM:
    "您确定要删除此项吗？",
  SETTING_WEBHOOK_AFTER_THIS_ACTION_THE_ITEM_WILL_BE_DELETED_IMMEDIATELY_AND_YOU_CANT_UNDO_THIS_ACTION:
    "在此操作后，该项将立即被删除，您无法撤消此操作。",
  SETTING_WEBHOOK_DELETE: "删除",
};
