export const DocumentSettingsEnglish = {
  SETTINGS_DOCUMENT_SETTINGS_SAVE: "Save",
  SETTINGS_DOCUMENT_SETTINGS_NOTE: "Note:",
  SETTINGS_DOCUMENT_SETTINGS_IF_OPTION_CHECKED_CHOOSE_DESIGN_FROM_POPUP_WHILE_PRINTING_LABEL:
    "If this option will be checked then you will always have to choose design from pop up while print label.",
  SETTINGS_DOCUMENT_SETTINGS_ASK_EVERY_TIME: "Ask Every Time",
};
export const DocumentSettingsArabic = {
  SETTINGS_DOCUMENT_SETTINGS_SAVE: "حفظ",
  SETTINGS_DOCUMENT_SETTINGS_NOTE: "ملاحظة:",
  SETTINGS_DOCUMENT_SETTINGS_IF_OPTION_CHECKED_CHOOSE_DESIGN_FROM_POPUP_WHILE_PRINTING_LABEL:
    "إذا تم تحديد هذا الخيار، فسيتعين عليك دائمًا اختيار التصميم من النافذة المنبثقة عند طباعة الملصق.",
  SETTINGS_DOCUMENT_SETTINGS_ASK_EVERY_TIME: "اسأل في كل مرة",
};
export const DocumentSettingsChinese = {
  SETTINGS_DOCUMENT_SETTINGS_SAVE: "保存",
  SETTINGS_DOCUMENT_SETTINGS_NOTE: "注意:",
  SETTINGS_DOCUMENT_SETTINGS_IF_OPTION_CHECKED_CHOOSE_DESIGN_FROM_POPUP_WHILE_PRINTING_LABEL:
    "如果选中此选项，则每次打印标签时都需要从弹出窗口中选择设计。",
  SETTINGS_DOCUMENT_SETTINGS_ASK_EVERY_TIME: "每次询问",
};
