import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
import DataGridTabs from "../../../.reUseableComponents/DataGridTabs/DataGridTabs";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import { styleSheet } from "../../../assets/styles/style";
import {
  ActionButtonCustom,
  greyBorder,
} from "../../../utilities/helpers/Helpers";
import UtilityClass from "../../../utilities/UtilityClass";
import { useState } from "react";
import PaymentsIcon from '@mui/icons-material/Payments';
import CODWalletList from "./list";
const CODWallet = () => {
  const { startDate, endDate, setStartDate, setEndDate, resetDates } =
    useDateRangeHook();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isfilterClear, setIsfilterClear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterClear = async () => {
    setIsfilterClear(true);
    resetDates();
  };

  const handleFilter = () => {};

  const cardData = [
    {
      icon: <LocalAtmIcon style={{ fontSize: 40 }} />,
      title: "Upcoming Transfer",
      amount: "0 AED",
      subText: "Expected Transfer 02 November, 2024",
    },
    {
      icon: <AccountBalanceWalletIcon style={{ fontSize: 40 }} />,
      title: "Current Balance",
      amount: "0 AED",
      subText: "",
    },
    {
      icon: <AccountBalanceIcon style={{ fontSize: 40 }} />,
      title: "Amount Withdrawn",
      amount: "0 AED",
      subText: "",
    },
  ];

  return (
    <>
      <Box display="flex" gap={2} p={1.5}>
        {cardData.map((data, index) => (
          <Card
            key={index}
            sx={{
              minWidth: 408,
              bgcolor: "#f5f5f5",
              border: greyBorder,
              boxShadow: "none",
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" mb={1}>
                {data.icon}
                <Box ml={2}>
                  <Typography variant="body2" color="textSecondary">
                    {data.title}
                  </Typography>
                  <Typography variant="h5" component="div" fontWeight="bold">
                    {data.amount}
                  </Typography>
                </Box>
              </Box>
              {data.subText && (
                <Typography variant="body2" color="textSecondary">
                  {data.subText}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box sx={styleSheet.pageRoot}>
        <div style={{ padding: "10px" }}>
          <Box sx={styleSheet.topNavBar}>
            <Box
              sx={{ ...styleSheet.topNavBarLeft, fontWeight: "900 !important" }}
            ></Box>
            <Stack
              sx={styleSheet.topNavBarRight}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <ButtonGroup
                variant="outlined"
                aria-label="split button"
              ></ButtonGroup>
            </Stack>
          </Box>
          <DataGridTabs
            filterData={
              isFilterOpen ? (
                <Table
                  sx={{ ...styleSheet.generalFilterArea }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <Grid container spacing={2} sx={{ p: "15px" }}>
                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Grid>
                            <InputLabel
                              sx={{
                                ...styleSheet.inputLabel,
                                overflow: "unset",
                              }}
                            >
                              {"Start Date"}
                            </InputLabel>
                            <CustomReactDatePickerInputFilter
                              value={startDate}
                              onClick={(date) => setStartDate(date)}
                              size="small"
                              isClearable
                              maxDate={UtilityClass.todayDate()}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Grid>
                            <InputLabel
                              sx={{
                                ...styleSheet.inputLabel,
                                overflow: "unset",
                              }}
                            >
                              {"End Date"}
                            </InputLabel>
                            <CustomReactDatePickerInputFilter
                              value={endDate}
                              onClick={(date) => setEndDate(date)}
                              size="small"
                              minDate={startDate}
                              disabled={!startDate ? true : false}
                              isClearable
                              maxDate={UtilityClass.todayDate()}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                          <Stack
                            alignItems="flex-end"
                            direction="row"
                            spacing={1}
                            sx={{
                              ...styleSheet.filterButtonMargin,
                              height: "100%",
                            }}
                          >
                            <Button
                              sx={{
                                ...styleSheet.filterIcon,
                                minWidth: "100px",
                              }}
                              color="inherit"
                              variant="outlined"
                              onClick={() => {
                                handleFilterClear();
                              }}
                            >
                              {
                                LanguageReducer?.languageType
                                  ?.ORDER_CLEAR_FILTER
                              }
                            </Button>
                            <Button
                              sx={{
                                ...styleSheet.filterIcon,
                                minWidth: "100px",
                              }}
                              variant="contained"
                              onClick={handleFilter}
                            >
                              {LanguageReducer?.languageType?.ORDERS_FILTER}
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </TableRow>
                  </TableHead>
                </Table>
              ) : null
            }
            tabData={[
              {
                label: "All",
                route: "/cod-wallet",
                children: (
                  <CODWalletList
                    loading={loading}
                    isFilterOpen={isFilterOpen}
                  />
                ),
              },
            ]}
            otherBtns={
              <ActionButtonCustom
                label={"Withdraw"}
                startIcon={<PaymentsIcon fontSize="small" />}
                // onClick={}
              />
            }
            handleFilterBtnOnClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          />
        </div>
      </Box>
    </>
  );
};

export default CODWallet;
