export const commonKeyEnglish = {
  MORE_INFO_PLUS: "+ More Info",
  MORE_INFO_MINUS: "- More Info",
  ACTIVE: "Active",
  IN_ACTIVE: "In Active",
  DISMISS_TEXT: "Dismiss",
  FILTER: "Filter",
  CLEAR_FILTER: "Clear Filter",
  ACTION: "Action",
};
export const commonKeyArabic = {
  MORE_INFO_PLUS: "+ المزيد من المعلومات",
  MORE_INFO_MINUS: "- المزيد من المعلومات",
  ACTIVE: "نشيط",
  IN_ACTIVE: "غير نشط",
  DISMISS_TEXT: "رفض",
  FILTER: "تصفية",
  ACTION: "عمل",
  CLEAR_FILTER: "مسح الفلتر",
};
export const commonKeyChinese = {
  MORE_INFO_PLUS: "+ 更多信息",
  MORE_INFO_MINUS: "- 更多信息",
  ACTIVE: "积极的",
  IN_ACTIVE: "活跃中",
  DISMISS_TEXT: "忽略",
  FILTER: "筛选",
  ACTION: "操作",
  CLEAR_FILTER: "清除筛选",
};
