import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isNumericKeyInputPress: false,
  isApiCalling: {},
  filterModel: {},
  clientSubscription: {
    isTrialMode: false,
    data: {},
  },
};
const changeFlagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    changeNumericKeyInputPressFlag: (state, action) => {
      state.isNumericKeyInputPress = action.payload;
    },
    changeApiCallingFlag: (state, action) => {
      const apiName = action.payload.apiName;
      state.isApiCalling[apiName] = {};
      state.isApiCalling[apiName].loading = action.payload.loading;
      state.isApiCalling[apiName].msg = action.payload.msg;
    },
    changeApiFilterModel: (state, action) => {
      const apiName = action.payload.apiName;
      state.filterModel[apiName] = {};
      state.filterModel[apiName].start =
        action.payload.page * action.payload.pageSize;
      state.filterModel[apiName].length = action.payload.pageSize;
    },
    changeClientSubscription: (state, action) => {
      // console.log(action.payload);
      state.clientSubscription.isTrialMode = action.payload.isOnTrail;
      state.clientSubscription.data = action.payload;
    },
  },
});
export const {
  changeNumericKeyInputPressFlag,
  changeApiCallingFlag,
  changeApiFilterModel,
  changeClientSubscription,
} = changeFlagsSlice.actions;
export default changeFlagsSlice.reducer;
