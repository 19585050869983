export const SaleChannelProductEnglish = {
  PRODUCT_SALE_CHANNEL_PRODUCT_S_TEXT: "Sale Channel Product(s)",
  PRODUCT_SALE_CHANNEL_PRODUCT_TEXT: "Sale Channel Products",
  PRODUCT_SALE_CHANNEL_STORE_CHANNEL: "Store(s) Channel",
  PRODUCT_SALE_CHANNEL_PRODUCT_TITLE: "Title",
  PRODUCT_SALE_CHANNEL_PRODUCT_VENDOR: "Vendor",
  PRODUCT_SALE_CHANNEL_PRODUCT_PRODUCT_TYPE: "Product Type",
  PRODUCT_SALE_CHANNEL_PRODUCT_DESCRIPTION: "Description",
  PRODUCT_SALE_CHANNEL_PRODUCT_VARIANTS: "Variants",
  PRODUCT_SALE_CHANNEL_PRODUCT_INVENTORY_QTY: "Inventory Qty",
  PRODUCT_SALE_CHANNEL_PRODUCT_PRODUCT_PRICE: "Product Price",
  PRODUCT_SALE_CHANNEL_PRODUCT_FROM_DATE: "From Date",
  PRODUCT_SALE_CHANNEL_PRODUCT_TO_DATE: "To Date",
  PRODUCT_SALE_CHANNEL_PRODUCT_STORE: "Store",
  PRODUCT_SALE_CHANNEL_PRODUCT_SYNC_PRODUCT: "Sync Product",
  PRODUCT_SALE_CHANNEL_PRODUCT_SYNC_SALE_CHANNEL_PRODUCTS:
    "Sync Sale Channel Product(s)",
};
export const SaleChannelProductArabic = {
  PRODUCT_SALE_CHANNEL_PRODUCT_S_TEXT: "المنتجات في قناة البيع",
  PRODUCT_SALE_CHANNEL_PRODUCT_TEXT: "منتجات قنوات البيع",
  PRODUCT_SALE_CHANNEL_STORE_CHANNEL: "قنوات البيع",
  PRODUCT_SALE_CHANNEL_PRODUCT_TITLE: "العنوان",
  PRODUCT_SALE_CHANNEL_PRODUCT_VENDOR: "البائع",
  PRODUCT_SALE_CHANNEL_PRODUCT_PRODUCT_TYPE: "نوع المنتج",
  PRODUCT_SALE_CHANNEL_PRODUCT_DESCRIPTION: "الوصف",
  PRODUCT_SALE_CHANNEL_PRODUCT_VARIANTS: "المتغيرات",
  PRODUCT_SALE_CHANNEL_PRODUCT_INVENTORY_QTY: "كمية المخزون",
  PRODUCT_SALE_CHANNEL_PRODUCT_PRODUCT_PRICE: "سعر المنتج",
  PRODUCT_SALE_CHANNEL_PRODUCT_FROM_DATE: "من التاريخ",
  PRODUCT_SALE_CHANNEL_PRODUCT_TO_DATE: "إلى التاريخ",
  PRODUCT_SALE_CHANNEL_PRODUCT_STORE: "المتجر",
  PRODUCT_SALE_CHANNEL_PRODUCT_SYNC_PRODUCT: "مزامنة المنتج",
  PRODUCT_SALE_CHANNEL_PRODUCT_SYNC_SALE_CHANNEL_PRODUCTS:
    "مزامنة المنتجات في قناة البيع",
};
export const SaleChannelProductChines = {
  PRODUCT_SALE_CHANNEL_PRODUCT_S_TEXT: "销售渠道产品",
  PRODUCT_SALE_CHANNEL_PRODUCT_TEXT: "销售渠道产品",
  PRODUCT_SALE_CHANNEL_PRODUCT_TITLE: "标题",
  PRODUCT_SALE_CHANNEL_STORE_CHANNEL: "销售渠道",
  PRODUCT_SALE_CHANNEL_PRODUCT_VENDOR: "供应商",
  PRODUCT_SALE_CHANNEL_PRODUCT_PRODUCT_TYPE: "产品类型",
  PRODUCT_SALE_CHANNEL_PRODUCT_DESCRIPTION: "描述",
  PRODUCT_SALE_CHANNEL_PRODUCT_VARIANTS: "变种",
  PRODUCT_SALE_CHANNEL_PRODUCT_INVENTORY_QTY: "库存数量",
  PRODUCT_SALE_CHANNEL_PRODUCT_PRODUCT_PRICE: "产品价格",
  PRODUCT_SALE_CHANNEL_PRODUCT_FROM_DATE: "从日期",
  PRODUCT_SALE_CHANNEL_PRODUCT_TO_DATE: "到日期",
  PRODUCT_SALE_CHANNEL_PRODUCT_STORE: "店铺",
  PRODUCT_SALE_CHANNEL_PRODUCT_SYNC_PRODUCT: "同步产品",
  PRODUCT_SALE_CHANNEL_PRODUCT_SYNC_SALE_CHANNEL_PRODUCTS: "同步销售渠道产品",
};
