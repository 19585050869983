export const driverRecievableEnglish = {
  MY_CARRIER_DRIVER_RECIEVEABLE_TEXT: "Driver Recievable",
  MY_CARRIER_DRIVER_RECIEVEABLE_RECEIVABLE_NO: "Receivable No.",
  MY_CARRIER_DRIVER_RECIEVEABLE_DRIVER: "Driver",
  MY_CARRIER_DRIVER_RECIEVEABLE_RECEIVE_DATE: "Receive Date",
  MY_CARRIER_DRIVER_RECIEVEABLE_COD_TOTAL: "COD Total",
  MY_CARRIER_DRIVER_RECIEVEABLE_EXPENSE: "Expense",
  MY_CARRIER_DRIVER_RECIEVEABLE_OTHER_COLLECTIONS: "Other Collections",
  MY_CARRIER_DRIVER_RECIEVEABLE_CASH_RECEIVABLE: "Cash Receivable",
  MY_CARRIER_DRIVER_RECIEVEABLE_ORDER_NO: "Order No.",
  MY_CARRIER_DRIVER_RECIEVEABLE_STORE_INFO: "Store Info.",
  MY_CARRIER_DRIVER_RECIEVEABLE_CUSTOMER: "Customer",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAYMENT_STATUS: "Payment Status",
  MY_CARRIER_DRIVER_RECIEVEABLE_ORDER_TYPE: "Order Type",
  MY_CARRIER_DRIVER_RECIEVEABLE_TRACKING_STATUS: "Tracking Status",
  MY_CARRIER_DRIVER_RECIEVEABLE_REMARKS: "Remarks",
  MY_CARRIER_DRIVER_RECIEVEABLE_DISCOUNT: "Discount",
  MY_CARRIER_DRIVER_RECIEVEABLE_AMOUNT: "Amount",
  MY_CARRIER_DRIVER_RECIEVEABLE_UNABLE_TO_DOWNLOAD: "Unable to download",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAID_SUCCESSFULLY: "Paid successfully",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAID_UNSUCCESSFULLY: "Paid unsuccessfull",
};

export const driverRecievableArabic = {
  MY_CARRIER_DRIVER_RECIEVEABLE_TEXT: "مستحقات السائق",
  MY_CARRIER_DRIVER_RECIEVEABLE_RECEIVABLE_NO: "رقم القابل للتحصيل",
  MY_CARRIER_DRIVER_RECIEVEABLE_DRIVER: "السائق",
  MY_CARRIER_DRIVER_RECIEVEABLE_RECEIVE_DATE: "تاريخ الاستلام",
  MY_CARRIER_DRIVER_RECIEVEABLE_COD_TOTAL: "إجمالي الدفع عند الاستلام",
  MY_CARRIER_DRIVER_RECIEVEABLE_EXPENSE: "المصروفات",
  MY_CARRIER_DRIVER_RECIEVEABLE_OTHER_COLLECTIONS: "التحصيلات الأخرى",
  MY_CARRIER_DRIVER_RECIEVEABLE_CASH_RECEIVABLE: "النقدية القابلة للتحصيل",
  MY_CARRIER_DRIVER_RECIEVEABLE_ORDER_NO: "رقم الطلب",
  MY_CARRIER_DRIVER_RECIEVEABLE_STORE_INFO: "معلومات المتجر",
  MY_CARRIER_DRIVER_RECIEVEABLE_CUSTOMER: "الزبون",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAYMENT_STATUS: "حالة الدفع",
  MY_CARRIER_DRIVER_RECIEVEABLE_ORDER_TYPE: "نوع الطلب",
  MY_CARRIER_DRIVER_RECIEVEABLE_TRACKING_STATUS: "حالة التتبع",
  MY_CARRIER_DRIVER_RECIEVEABLE_REMARKS: "ملاحظات",
  MY_CARRIER_DRIVER_RECIEVEABLE_DISCOUNT: "خصم",
  MY_CARRIER_DRIVER_RECIEVEABLE_UNABLE_TO_DOWNLOAD: "غير قادر على التنزيل",
  MY_CARRIER_DRIVER_RECIEVEABLE_AMOUNT: "المبلغ",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAID_SUCCESSFULLY: "تم الدفع بنجاح",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAID_UNSUCCESSFULLY: "فشل في الدفع",
};
export const driverRecievableChines = {
  MY_CARRIER_DRIVER_RECIEVEABLE_TEXT: " 驾驶员应收款",
  MY_CARRIER_DRIVER_RECIEVEABLE_RECEIVABLE_NO: "应收款编号",
  MY_CARRIER_DRIVER_RECIEVEABLE_DRIVER: "司机",
  MY_CARRIER_DRIVER_RECIEVEABLE_RECEIVE_DATE: "收款日期",
  MY_CARRIER_DRIVER_RECIEVEABLE_COD_TOTAL: "货到付款总额",
  MY_CARRIER_DRIVER_RECIEVEABLE_EXPENSE: "费用",
  MY_CARRIER_DRIVER_RECIEVEABLE_OTHER_COLLECTIONS: "其他收款",
  MY_CARRIER_DRIVER_RECIEVEABLE_CASH_RECEIVABLE: "现金应收款",
  MY_CARRIER_DRIVER_RECIEVEABLE_ORDER_NO: "订单号",
  MY_CARRIER_DRIVER_RECIEVEABLE_STORE_INFO: "店铺信息",
  MY_CARRIER_DRIVER_RECIEVEABLE_CUSTOMER: "客户",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAYMENT_STATUS: "支付状态",
  MY_CARRIER_DRIVER_RECIEVEABLE_ORDER_TYPE: "订单类型",
  MY_CARRIER_DRIVER_RECIEVEABLE_TRACKING_STATUS: "跟踪状态",
  MY_CARRIER_DRIVER_RECIEVEABLE_REMARKS: "备注",
  MY_CARRIER_DRIVER_RECIEVEABLE_DISCOUNT: "折扣",
  MY_CARRIER_DRIVER_RECIEVEABLE_AMOUNT: "金额",
  MY_CARRIER_DRIVER_RECIEVEABLE_UNABLE_TO_DOWNLOAD: "无法下载",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAID_SUCCESSFULLY: "支付成功",
  MY_CARRIER_DRIVER_RECIEVEABLE_PAID_UNSUCCESSFULLY: "支付失败",
};
