export const permissionEnglish = {
  SETTING_PERMISSION_TEXT: "Permissions",
  SETTING_PERMISSION_ADD_USER_ROLE: "Add User Role",
  SETTING_PERMISSION_UPDATE_PERMISSIONS: "Update Permissions",
  SETTING_PERMISSION_SELECT_ALL: "Select All",
  SETTING_PERMISSION_EXPAND_ALL: "Expand All",
  SETTING_PERMISSION_ORDER: "Order",
  SETTING_PERMISSION_OTHER: "Other",
  SETTING_PERMISSION_LOOKUP: "Lookup",
  SETTING_PERMISSION_ADMIN: "Admin",
  SETTING_PERMISSION_DEPRECIATED: "Depreciated",
  SETTING_PERMISSION_CARRIER: "Carrier",
  SETTING_PERMISSION_PRODUCT: "Product",
  SETTING_PERMISSION_STORE: "Store",
  SETTING_PERMISSION_SHIPMENT: "Shipment",
  SETTING_PERMISSION_ACCOUNT: "Account",
  SETTING_PERMISSION_ADD_USER_ROLES: "Add User Roles",
  SETTING_PERMISSION_ADD_ROLE: "Add Role",
  SETTING_PERMISSION_ROLE_NAME: "Role Name",
  SETTING_PERMISSION_ROLE_DESCRIPTION: "Role Description",
};
export const permissionArabic = {
  SETTING_PERMISSION_TEXT: "الأذونات",
  SETTING_PERMISSION_ADD_USER_ROLE: "إضافة دور مستخدم",
  SETTING_PERMISSION_UPDATE_PERMISSIONS: "تحديث الصلاحيات",
  SETTING_PERMISSION_SELECT_ALL: "تحديد الكل",
  SETTING_PERMISSION_EXPAND_ALL: "توسيع الكل",
  SETTING_PERMISSION_ORDER: "الطلب",
  SETTING_PERMISSION_OTHER: "آخر",
  SETTING_PERMISSION_LOOKUP: "البحث",
  SETTING_PERMISSION_ADMIN: "المشرف",
  SETTING_PERMISSION_DEPRECIATED: "مهمل",
  SETTING_PERMISSION_CARRIER: "الناقل",
  SETTING_PERMISSION_PRODUCT: "المنتج",
  SETTING_PERMISSION_STORE: "المتجر",
  SETTING_PERMISSION_SHIPMENT: "الشحن",
  SETTING_PERMISSION_ACCOUNT: "الحساب",
  SETTING_PERMISSION_ADD_USER_ROLES: "إضافة أدوار المستخدم",
  SETTING_PERMISSION_ADD_ROLE: "إضافة دور",
  SETTING_PERMISSION_ROLE_NAME: "اسم الدور",
  SETTING_PERMISSION_ROLE_DESCRIPTION: "وصف الدور",
};
export const permissionChines = {
  SETTING_PERMISSION_TEXT: "Permissions",
  SETTING_PERMISSION_ADD_USER_ROLE: "添加用户角色",
  SETTING_PERMISSION_UPDATE_PERMISSIONS: "更新权限",
  SETTING_PERMISSION_SELECT_ALL: "选择全部",
  SETTING_PERMISSION_EXPAND_ALL: "展开全部",
  SETTING_PERMISSION_ORDER: "订单",
  SETTING_PERMISSION_OTHER: "其他",
  SETTING_PERMISSION_LOOKUP: "查找",
  SETTING_PERMISSION_ADMIN: "管理员",
  SETTING_PERMISSION_DEPRECIATED: "已弃用",
  SETTING_PERMISSION_CARRIER: "承运商",
  SETTING_PERMISSION_PRODUCT: "产品",
  SETTING_PERMISSION_STORE: "产品",
  SETTING_PERMISSION_SHIPMENT: "货运",
  SETTING_PERMISSION_ACCOUNT: "账户",
  SETTING_PERMISSION_ADD_USER_ROLES: "添加用户角色",
  SETTING_PERMISSION_ADD_ROLE: "添加角色",
  SETTING_PERMISSION_ROLE_NAME: "角色名称",
  SETTING_PERMISSION_ROLE_DESCRIPTION: "角色描述",
};
