export const smsIntegrationEnglish = {
  INTEGRATION_SMS_INTEGRATION_TEXT: "SMS Integration",
  INTEGRATION_SMS_INTEGRATION_ALL: "All",
  INTEGRATION_SMS_INTEGRATION_ACTIVE: "Active",
  INTEGRATION_SMS_INTEGRATION_INACTIVE: "Inactive",
  INTEGRATION_SMS_INTEGRATION_SERVICE_NAME: "Service Name",
  INTEGRATION_SMS_INTEGRATION_CREATE_DATE: "Create Date",
  INTEGRATION_SMS_INTEGRATION_STATUS: "Status",
  INTEGRATION_SMS_INTEGRATION_DEFAULT: "Default",
  INTEGRATION_SMS_INTEGRATION_ADD_SMS_CONFIG: "Add Sms Config",
  INTEGRATION_SMS_INTEGRATION_SELECT_SMS_SERVICE: "Select Sms Service",
  INTEGRATION_SMS_INTEGRATION_MARK_AS_DEFAULT: "Mark As Default",
  INTEGRATION_SMS_INTEGRATION_ADD_SMS_CONFIG: "Add Sms Config",
  INTEGRATION_SMS_INTEGRATION_SELECT_SMS_SERVICE: "Select Sms Service",
  INTEGRATION_SMS_INTEGRATION_MARK_AS_DEFAULT: "Mark as default",
};
export const smsIntegrationArabic = {
  INTEGRATION_SMS_INTEGRATION_TEXT: "تكامل الرسائل النصية",
  INTEGRATION_SMS_INTEGRATION_ALL: "الجميع",
  INTEGRATION_SMS_INTEGRATION_ACTIVE: "نشط",
  INTEGRATION_SMS_INTEGRATION_INACTIVE: "غير نشط",
  INTEGRATION_SMS_INTEGRATION_SERVICE_NAME: "اسم الخدمة",
  INTEGRATION_SMS_INTEGRATION_CREATE_DATE: "تاريخ الإنشاء",
  INTEGRATION_SMS_INTEGRATION_STATUS: "الحالة",
  INTEGRATION_SMS_INTEGRATION_DEFAULT: "افتراضي",
  INTEGRATION_SMS_INTEGRATION_ADD_SMS_CONFIG: "إضافة إعدادات الرسائل النصية",
  INTEGRATION_SMS_INTEGRATION_SELECT_SMS_SERVICE: "اختر خدمة الرسائل القصيرة",
  INTEGRATION_SMS_INTEGRATION_MARK_AS_DEFAULT: "وضع علامة كإعداد افتراضي",
  INTEGRATION_SMS_INTEGRATION_ADD_SMS_CONFIG: "إضافة إعدادات SMS",
  INTEGRATION_SMS_INTEGRATION_SELECT_SMS_SERVICE: "اختر خدمة SMS",
  INTEGRATION_SMS_INTEGRATION_MARK_AS_DEFAULT: "اجعلها افتراضية",
};
export const smsIntegrationChines = {
  INTEGRATION_SMS_INTEGRATION_TEXT: "短信集成",
  INTEGRATION_SMS_INTEGRATION_ALL: "全部",
  INTEGRATION_SMS_INTEGRATION_ACTIVE: "活跃的",
  INTEGRATION_SMS_INTEGRATION_INACTIVE: "非活跃的",
  INTEGRATION_SMS_INTEGRATION_SERVICE_NAME: "服务名称",
  INTEGRATION_SMS_INTEGRATION_CREATE_DATE: "创建日期",
  INTEGRATION_SMS_INTEGRATION_STATUS: "状态",
  INTEGRATION_SMS_INTEGRATION_DEFAULT: "默认",
  INTEGRATION_SMS_INTEGRATION_ADD_SMS_CONFIG: "添加短信配置",
  INTEGRATION_SMS_INTEGRATION_SELECT_SMS_SERVICE: "选择短信服务",
  INTEGRATION_SMS_INTEGRATION_MARK_AS_DEFAULT: "标记为默认",
  INTEGRATION_SMS_INTEGRATION_ADD_SMS_CONFIG: "添加短信配置",
  INTEGRATION_SMS_INTEGRATION_SELECT_SMS_SERVICE: "选择短信服务",
  INTEGRATION_SMS_INTEGRATION_MARK_AS_DEFAULT: "标记为默认",
};
