import { Box } from "@mui/material";
import React from "react";
import { styleSheet } from "../../../../assets/styles/style";
import {
  navbarHeight,
  useGetWindowHeight,
} from "../../../../utilities/helpers/Helpers";
import { useSelector } from "react-redux";
import { usePagination } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";

const PayoutBankList = (props) => {
  const { loading, isFilterOpen } = props;
  const { height: windowHeight } = useGetWindowHeight();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const { currentPage, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination(0, 10);
  const calculatedHeight = windowHeight - 130;
  const column = [];
  const rows = [];
  return (
    <>
      <Box
        sx={{
          ...styleSheet.allOrderTable,
          height: calculatedHeight,
        }}
      >
        <DataGrid
          loading={loading}
          rowHeight={40}
          headerHeight={40}
          sx={{
            fontFamily: "'Lato Regular', 'Inter Regular', 'Arial' !important",
            fontSize: "12px",
            fontWeight: "500",
          }}
          getRowId={(row) => row.RowNum}
          rows={rows || []}
          columns={column}
          disableSelectionOnClick
          pagination
          page={currentPage}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 15, 25]}
          paginationMode="client"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </>
  );
};

export default PayoutBankList;
