export const deliveryNotesEnglish = {
  MY_CARRIER_DELIVERY_NOTE_S_TEXT: "Delivery Note(s)",
  MY_CARRIER_DELIVERY_NOTES_TEXT: "Delivery Notes",
  MY_CARRIER_DELIVERY_TASKS_All: "All",
  MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS: "In Progress",
  MY_CARRIER_DELIVERY_NOTES_COMPLETED: "Completed",
  MY_CARRIER_DELIVERY_NOTES_COMPLETE: "Complete",
  MY_CARRIER_DELIVERY_NOTES_NOTE_NO: "Note No.",
  MY_CARRIER_DELIVERY_NOTES_CATEGORY: "Category",
  MY_CARRIER_DELIVERY_NOTES_ADD_EXPENSE: "Add Expense",
  MY_CARRIER_DELIVERY_NOTES_EXPENSE_DATE: "Expense Date",
  MY_CARRIER_DELIVERY_NOTES_NO_RESULT_ADD: "No results! Add",
  MY_CARRIER_DELIVERY_NOTES_DRIVER: "Driver",
  MY_CARRIER_DELIVERY_NOTES_SHIPMENTS_COUNT: "Shipment(s) Count",
  MY_CARRIER_DELIVERY_NOTES_NOTE_STATUS: "Note Status",
  MY_CARRIER_DELIVERY_NOTES_DATE: "Date",
  MY_CARRIER_DELIVERY_NOTES_ACTION: "Action",
  MY_CARRIER_DELIVERY_NOTES_DRBRIEF: "Debrief",
  MY_CARRIER_DELIVERY_NOTES_ORDER_NO: " Order No. ",
  MY_CARRIER_DELIVERY_NOTES_IN_OPERATION: "In operation",
  MY_CARRIER_DELIVERY_NOTES_PENDING: "Pending",
  MY_CARRIER_DELIVERY_NOTES_CUSTOMER_INFO: " Customer Info. ",
  MY_CARRIER_DELIVERY_NOTES_TRACKING_STATUS: "Tracking Status",
  MY_CARRIER_DELIVERY_NOTES_UPDATE_BY_DRIVER: "Updated by Driver",
  MY_CARRIER_DELIVERY_NOTES_NOTE_DETAIL_STATUS: "Note Detail Status",
  MY_CARRIER_DELIVERY_NOTES_REMARKS: " Remarks",
  MY_CARRIER_DELIVERY_NOTES_AMOUNT: " Amount",
  MY_CARRIER_DELIVERY_NOTES_PENDING: "Pending",
  MY_CARRIER_DELIVERY_NOTES_REVERT: "Revert",
  MY_CARRIER_DELIVERY_NOTES_PRINT: "Print",
  MY_CARRIER_DELIVERY_NOTES_PENDING_FOR_RETURN: "Pending For Return",
  MY_CARRIER_DELIVERY_NOTES_CATEGORY_CREATED_SUCCESSFULLY:
    "Category created successfully",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_CHOOSE_CATEGORY: "Please Choose Category",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_SELECT_DATE: "Please select date",
  MY_CARRIER_DELIVERY_NOTES_SOMETHING_WENT_WRONG: "Something went wrong",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_MAKE_SURE_ALL_ORDERS_ARE_MARKED_TO_COMPLETE_THIS_NOTE:
    "Please make sure all orders are marked to complete this note",
};
export const deliveryNotesArabic = {
  MY_CARRIER_DELIVERY_NOTE_S_TEXT: "ملاحظات التسليم(s)",
  MY_CARRIER_DELIVERY_NOTES_TEXT: "ملاحظات التسليم",
  MY_CARRIER_DELIVERY_TASKS_All: "الجميع",
  MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS: "قيد التنفيذ",
  MY_CARRIER_DELIVERY_NOTES_COMPLETED: "مكتمل",
  MY_CARRIER_DELIVERY_NOTES_NOTE_NO: "رقم الملاحظة",
  MY_CARRIER_DELIVERY_NOTES_DRIVER: "السائق",
  MY_CARRIER_DELIVERY_NOTES_SHIPMENTS_COUNT: "عدد الشحنات",
  MY_CARRIER_DELIVERY_NOTES_NOTE_STATUS: "حالة الملاحظة",
  MY_CARRIER_DELIVERY_NOTES_DATE: "التاريخ",
  MY_CARRIER_DELIVERY_NOTES_ACTION: "فعل",
  MY_CARRIER_DELIVERY_NOTES_PENDING_FOR_RETURN: "قيد الانتظار للإرجاع",
  MY_CARRIER_DELIVERY_NOTES_DRBRIEF: "إحاطة",
  MY_CARRIER_DELIVERY_NOTES_ORDER_NO: "رقم الطلب",
  MY_CARRIER_DELIVERY_NOTES_CUSTOMER_INFO: "معلومات العميل",
  MY_CARRIER_DELIVERY_NOTES_TRACKING_STATUS: "حالة التتبع",
  MY_CARRIER_DELIVERY_NOTES_UPDATE_BY_DRIVER: "تم التحديث من قبل السائق",
  MY_CARRIER_DELIVERY_NOTES_NOTE_DETAIL_STATUS: "حالة تفاصيل الملاحظة",
  MY_CARRIER_DELIVERY_NOTES_REMARKS: "ملاحظات",
  MY_CARRIER_DELIVERY_NOTES_PRINT: "طباعة",
  MY_CARRIER_DELIVERY_NOTES_CATEGORY: "الفئة",
  MY_CARRIER_DELIVERY_NOTES_ADD_EXPENSE: "إضافة مصروف",
  MY_CARRIER_DELIVERY_NOTES_EXPENSE_DATE: "تاريخ المصروف",
  MY_CARRIER_DELIVERY_NOTES_NO_RESULT_ADD: "لا توجد نتائج! أضف",
  MY_CARRIER_DELIVERY_NOTES_IN_OPERATION: "قيد التشغيل",
  MY_CARRIER_DELIVERY_NOTES_PENDING: "قيد الانتظار",
  MY_CARRIER_DELIVERY_NOTES_AMOUNT: "المبلغ",
  MY_CARRIER_DELIVERY_NOTES_COMPLETE: "مكتمل",
  MY_CARRIER_DELIVERY_NOTES_PENDING: "قيد الانتظار",
  MY_CARRIER_DELIVERY_NOTES_REVERT: "الرجوع",
  MY_CARRIER_DELIVERY_NOTES_CATEGORY_CREATED_SUCCESSFULLY:
    "تم إنشاء الفئة بنجاح",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_CHOOSE_CATEGORY: "يرجى اختيار الفئة",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_SELECT_DATE: "يرجى تحديد التاريخ",
  MY_CARRIER_DELIVERY_NOTES_SOMETHING_WENT_WRONG: "حدث خطأ ما",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_MAKE_SURE_ALL_ORDERS_ARE_MARKED_TO_COMPLETE_THIS_NOTE:
    "يرجى التأكد من وضع علامة على جميع الطلبات لإكمال هذه الملاحظة",
};
export const deliveryNotesChines = {
  MY_CARRIER_DELIVERY_NOTE_S_TEXT: "交货单(s)",
  MY_CARRIER_DELIVERY_NOTES_TEXT: "交货单",
  MY_CARRIER_DELIVERY_TASKS_All: "全部",
  MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS: "进行中",
  MY_CARRIER_DELIVERY_NOTES_COMPLETED: "已完成",
  MY_CARRIER_DELIVERY_NOTES_NOTE_NO: "备注编号",
  MY_CARRIER_DELIVERY_NOTES_DRIVER: "司机",
  MY_CARRIER_DELIVERY_NOTES_SHIPMENTS_COUNT: "发货数量",
  MY_CARRIER_DELIVERY_NOTES_NOTE_STATUS: "备注状态",
  MY_CARRIER_DELIVERY_NOTES_DATE: "日期",
  MY_CARRIER_DELIVERY_NOTES_ACTION: "行动",
  MY_CARRIER_DELIVERY_NOTES_DRBRIEF: "汇报",
  MY_CARRIER_DELIVERY_NOTES_IN_OPERATION: "正在操作中",
  MY_CARRIER_DELIVERY_NOTES_PENDING: "待处理",
  MY_CARRIER_DELIVERY_NOTES_ORDER_NO: "订单号",
  MY_CARRIER_DELIVERY_NOTES_CUSTOMER_INFO: "客户信息",
  MY_CARRIER_DELIVERY_NOTES_TRACKING_STATUS: "追踪状态",
  MY_CARRIER_DELIVERY_NOTES_UPDATE_BY_DRIVER: "由司机更新",
  MY_CARRIER_DELIVERY_NOTES_NOTE_DETAIL_STATUS: "备注详情状态",
  MY_CARRIER_DELIVERY_NOTES_REMARKS: "备注",
  MY_CARRIER_DELIVERY_NOTES_AMOUNT: "金额",
  MY_CARRIER_DELIVERY_NOTES_PENDING: "待处理",
  MY_CARRIER_DELIVERY_NOTES_PRINT: "打印",
  MY_CARRIER_DELIVERY_NOTES_REVERT: "恢复",
  MY_CARRIER_DELIVERY_NOTES_PENDING_FOR_RETURN: "待退还",
  MY_CARRIER_DELIVERY_NOTES_CATEGORY_CREATED_SUCCESSFULLY: "类别创建成功",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_CHOOSE_CATEGORY: "请选择类别",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_SELECT_DATE: "请选择日期",
  MY_CARRIER_DELIVERY_NOTES_CATEGORY: "类别",
  MY_CARRIER_DELIVERY_NOTES_ADD_EXPENSE: "添加费用",
  MY_CARRIER_DELIVERY_NOTES_EXPENSE_DATE: "费用日期",
  MY_CARRIER_DELIVERY_NOTES_COMPLETE: "已完成",
  MY_CARRIER_DELIVERY_NOTES_NO_RESULT_ADD: "没有结果！添加",
  MY_CARRIER_DELIVERY_NOTES_SOMETHING_WENT_WRONG: "发生错误",
  MY_CARRIER_DELIVERY_NOTES_PLEASE_MAKE_SURE_ALL_ORDERS_ARE_MARKED_TO_COMPLETE_THIS_NOTE:
    "请确保所有订单都已标记为完成此备注",
};
